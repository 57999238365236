import clsx from "clsx";
import React from "react";

import Link from "next/link";
import { makeStyles } from "../styles/makeStyles";
import { LoadingButton } from "./loadingButton";

const useStyles = makeStyles<{ fontWeight: CymbalButtonFontWeight; fontSize?: string | number }>()(
    (theme, { fontWeight, fontSize }) => ({
        fullWidth: {
            width: "100%",
        },
        button: {
            fontWeight,
            fontSize,
            "&.Mui-disabled": {
                opacity: 0.7,
            },
        },
        smallButton: {
            height: 42,
            borderRadius: 21,
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            fontSize: "0.875rem",
        },
        primary: {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: "#f2f2f2",
            },
            "&.Mui-disabled": {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.primary.main,
            },
        },
        secondary: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
                backgroundColor: "#404040",
            },
            "&.Mui-disabled": {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.main,
            },
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            "&:hover": {
                backgroundColor: "#1f202c",
            },
            "&.Mui-disabled": {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            },
        },
        paperHighlight: {
            backgroundColor: theme.palette.background.paperHighlight,
            color: theme.palette.text.primary,
            "&:hover": {
                backgroundColor: "#28292b",
            },
            "&.Mui-disabled": {
                backgroundColor: theme.palette.background.paperHighlight,
                color: theme.palette.text.primary,
            },
        },
        paperDoubleHighlight: {
            backgroundColor: theme.palette.background.paperDoubleHighlight,
            color: theme.palette.text.primary,
            "&:hover": {
                backgroundColor: "#333436",
            },
            "&.Mui-disabled": {
                backgroundColor: theme.palette.background.paperDoubleHighlight,
                color: theme.palette.text.primary,
            },
        },
        error: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.text.primary,
            "&:hover": {
                backgroundColor: "#943622",
            },
            "&.Mui-disabled": {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.text.primary,
            },
        },
    }),
);

export type CymbalButtonColor =
    | "primary"
    | "secondary"
    | "paper"
    | "paper-highlight"
    | "paper-double-highlight"
    | "error";

type CymbalButtonFontWeight = 300 | 500;

interface AnchorProps {
    href: string;
    isNewTab?: boolean;
    target?: React.HTMLAttributeAnchorTarget;
    rel?: string;
}

interface Props {
    className?: string;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    color?: CymbalButtonColor;
    isDisabled?: boolean;
    isLoading?: boolean;
    fontSize?: string;
    fontWeight?: CymbalButtonFontWeight;
    fullWidth?: boolean;
    small?: boolean;
    anchorProps?: AnchorProps;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const defaultNewTabAnchorProps = {
    target: "_blank",
    rel: "noopener noreferrer",
};

export const CymbalButton: React.FC<React.PropsWithChildren<Props>> = React.memo(
    ({
        className,
        buttonRef,
        fontSize,
        color = "primary",
        fontWeight = 500,
        isLoading = false,
        isDisabled = false,
        small = false,
        fullWidth = false,
        children,
        anchorProps,
        onClick,
    }) => {
        const { classes } = useStyles({ fontWeight, fontSize });

        const smallClassName = React.useMemo(() => (small ? classes.smallButton : undefined), [small, classes]);

        const colorClassName = React.useMemo(
            () => getColorClassName({ buttonColor: color, classes }),
            [color, classes],
        );

        const Button = React.useMemo(
            () => (
                <LoadingButton
                    className={clsx(className, classes.button, smallClassName, colorClassName, "cymbal-button")}
                    ref={buttonRef}
                    variant="contained"
                    loading={isLoading}
                    disabled={isDisabled}
                    fullWidth={fullWidth}
                    onClick={onClick}
                >
                    {children}
                </LoadingButton>
            ),
            [
                buttonRef,
                children,
                className,
                classes.button,
                colorClassName,
                fullWidth,
                isDisabled,
                isLoading,
                smallClassName,
                onClick,
            ],
        );

        return !anchorProps ? (
            Button
        ) : (
            <Link
                className={fullWidth ? classes.fullWidth : undefined}
                href={anchorProps.href}
                rel={!!anchorProps.isNewTab ? defaultNewTabAnchorProps.rel : anchorProps.rel}
                target={!!anchorProps.isNewTab ? defaultNewTabAnchorProps.target : anchorProps.target}
            >
                {Button}
            </Link>
        );
    },
);

const getColorClassName = ({
    buttonColor,
    classes,
}: {
    buttonColor: CymbalButtonColor;
    classes: Record<"primary" | "secondary" | "paper" | "paperHighlight" | "paperDoubleHighlight" | "error", string>;
}) => {
    switch (buttonColor) {
        case "primary":
            return classes.primary;
        case "secondary":
            return classes.secondary;
        case "paper":
            return classes.paper;
        case "paper-highlight":
            return classes.paperHighlight;
        case "paper-double-highlight":
            return classes.paperDoubleHighlight;
        case "error":
            return classes.error;
    }
};
