import { useTheme } from "@mui/material/styles";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import React from "react";

import { config } from "../../../../shared/config";

type Props = React.PropsWithChildren<{
    clientSecret: string;
    variables?: Appearance["variables"];
    rules?: Appearance["rules"];
}>;

export const StripeElements: React.FC<Props> = React.memo(({ clientSecret, children, variables, rules }) => {
    const theme = useTheme();

    const [stripe] = React.useState(loadStripe(config.stripeApiKey));

    return (
        <Elements
            stripe={stripe}
            options={{
                clientSecret,
                fonts: [
                    {
                        cssSrc: "https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap",
                    },
                ],
                appearance: {
                    labels: "floating",
                    variables: {
                        colorTextPlaceholder: theme.palette.midGray,
                        colorBackground: theme.palette.background.paper,
                        colorText: theme.palette.text.primary,
                        colorTextSecondary: theme.palette.midGray,
                        colorDanger: theme.palette.error.main,
                        colorSuccess: theme.palette.success.main,
                        colorWarning: theme.palette.warning.main,
                        fontFamily: "Lexend Deca",
                        spacingUnit: "4px",
                        fontSizeBase: "1rem",
                        borderRadius: "8px",
                        focusBoxShadow: "none",
                        focusOutline: `1px solid ${theme.palette.text.primary}`,
                        ...variables,
                    },
                    rules,
                },
            }}
        >
            {children}
        </Elements>
    );
});
