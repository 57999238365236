import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import { IconProps } from "../util/icon";

export const CloseIcon: React.FC<IconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 32 32">
        <path d="M24.823 9.53a1.664 1.664 0 1 0-2.352-2.354L16 13.647l-6.47-6.47a1.664 1.664 0 1 0-2.354 2.352L13.647 16l-6.47 6.47a1.664 1.664 0 1 0 2.352 2.354L16 18.352l6.47 6.47a1.664 1.664 0 1 0 2.354-2.352L18.352 16l6.47-6.47Z" />
    </SvgIcon>
);
