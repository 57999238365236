import { captureException } from "@sentry/nextjs";
import React from "react";

import { ErrorPageContent } from "./errorPageContent";

interface Props {
    children?: React.ReactNode;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends React.PureComponent<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // eslint-disable-next-line no-console
        console.error("Uncaught error:", error, errorInfo);
        captureException(error);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorPageContent />;
        }

        return this.props.children;
    }
}
