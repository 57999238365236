import clsx from "clsx";
import React from "react";

import { DownChevronIcon } from "../icons/downChevron";
import { makeStyles } from "./makeStyles";

const useStyles = makeStyles()({
    tableSortChevron: {
        marginLeft: 6,
        fontSize: 12,
    },
});

interface Props {
    className?: string;
}

export const TableSortChevron: React.FC<Props> = React.memo(({ className }) => {
    const { classes } = useStyles();

    return <DownChevronIcon className={clsx(className, classes.tableSortChevron)} />;
});
