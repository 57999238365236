import type {
    ColumnLegoComponent,
    EventLegoComponent,
    FormLegoComponent,
    HorizontalEventLegoComponent,
    LeafLegoComponent,
    LegoComponent,
    LegoComponentGroup,
    LegoTemplate,
    QuestionLegoComponent,
} from "../models/legos";
import { MergedLegoTemplateStyles } from "../models/mergedLegos";
import { emptyLegoImageSmallUrl, emptyLegoImageUrl } from "./legos";
import { isValidUrl } from "./url";

export const getInvalidComponentIdLegoTemplateHeader = (mergedStyles: MergedLegoTemplateStyles) =>
    !!mergedStyles.header_style?.component_group
        ? getInvalidComponentIdLegoComponentGroup(mergedStyles.header_style?.component_group)
        : null;

export const getInvalidComponentIdLegoTemplateFooter = (mergedStyles: MergedLegoTemplateStyles) =>
    !!mergedStyles.footer_style.component_group
        ? getInvalidComponentIdLegoComponentGroup(mergedStyles.footer_style.component_group)
        : null;

export const getInvalidComponentIdLegoTemplate = (legoTemplate: LegoTemplate) =>
    getInvalidComponentIdLegoComponentGroup(legoTemplate.component_group);

interface ValidateLegoComponentOptions {
    onlyLeaf?: boolean;
    parentIsColumn?: boolean;
    parentIsForm?: boolean;
    parentIsQuestion?: boolean;
}

export const getInvalidComponentIdLegoComponentGroup = (
    legoComponentGroup: LegoComponentGroup,
    opts: ValidateLegoComponentOptions = {
        onlyLeaf: false,
        parentIsColumn: false,
        parentIsForm: false,
        parentIsQuestion: false,
    },
): number | null => {
    for (const { component } of legoComponentGroup.rows) {
        const invalidId = getInvalidComponentIdLegoComponent(component, opts);
        if (!!invalidId) {
            return invalidId;
        }
    }

    return null;
};

const getInvalidComponentIdLegoComponent = (
    legoComponent: LegoComponent,
    {
        onlyLeaf = false,
        parentIsColumn = false,
        parentIsForm = false,
        parentIsQuestion = false,
    }: ValidateLegoComponentOptions = {
        onlyLeaf: false,
        parentIsColumn: false,
        parentIsForm: false,
        parentIsQuestion: false,
    },
) => {
    const { type } = legoComponent;
    if (
        (onlyLeaf &&
            (type === "form" ||
                type === "question" ||
                type === "event" ||
                type === "horizontal-event" ||
                type === "column")) ||
        (parentIsColumn && (type === "column" || type === "form" || type === "question")) ||
        (parentIsForm &&
            (type === "form" ||
                type === "event" ||
                type === "horizontal-event" ||
                type === "column" ||
                (type === "leaf" && legoComponent.leaf_component.type === "button"))) ||
        (parentIsQuestion && (type !== "leaf" || legoComponent.leaf_component.type !== "option"))
    ) {
        return legoComponent.id;
    }

    let isValid = false;

    switch (type) {
        case "leaf":
            isValid = isValidLeafLegoComponent(legoComponent.leaf_component, {
                parentIsForm,
                parentIsQuestion,
            });
            break;
        case "form":
            isValid = isValidFormLegoComponent(legoComponent.form_component);
            break;
        case "question":
            isValid = isValidQuestionLegoComponent(legoComponent.question_component);
            break;
        case "event":
            isValid = isValidEventLegoComponent(legoComponent.event_component);
            break;
        case "column":
            isValid = isValidColumnLegoComponent(legoComponent.column_component);
            break;
        case "horizontal-event":
            isValid = isValidEventLegoComponent(legoComponent.horizontal_event_component);
            break;
        case "automation-triggered-event":
            isValid = true;
            break;
        case "automation-upcoming-events":
            isValid = true;
    }

    return isValid ? null : legoComponent.id;
};

interface ValidateLeafLegoComponentOptions {
    parentIsForm: boolean;
    parentIsQuestion: boolean;
}

const isValidLeafLegoComponent = (
    leafLegoComponent: LeafLegoComponent,
    { parentIsForm, parentIsQuestion }: ValidateLeafLegoComponentOptions,
) => {
    switch (leafLegoComponent.type) {
        case "text":
            return !!leafLegoComponent.text_component.text;
        case "button":
            return (
                leafLegoComponent.button_component.text !== "" &&
                (leafLegoComponent.button_component.href.startsWith("http://localhost") ||
                    isValidUrl(leafLegoComponent.button_component.href, { injectHttpProtocol: true }))
            );
        case "promo-code":
            return !!leafLegoComponent.promo_code_component.text;
        case "image":
            return (
                !!leafLegoComponent.image_component.src &&
                leafLegoComponent.image_component.src !== emptyLegoImageUrl &&
                leafLegoComponent.image_component.src != emptyLegoImageSmallUrl
            );
        case "divider":
            return true;
        case "spacer":
            return (leafLegoComponent.spacer_component.spacing_px ?? 0) > 0;
        case "input":
            return parentIsForm;
        case "option":
            return parentIsQuestion && leafLegoComponent.option_component.text != "";
    }
};

export const isValidFormLegoComponent = (formLegoComponent: FormLegoComponent) =>
    !!formLegoComponent.name &&
    !!formLegoComponent.button_text &&
    (!!formLegoComponent.success_component_group || !!formLegoComponent.redirect_url) &&
    (!formLegoComponent.success_component_group ||
        (!formLegoComponent.redirect_url &&
            !getInvalidComponentIdLegoComponentGroup(formLegoComponent.success_component_group, { onlyLeaf: true }))) &&
    (!formLegoComponent.redirect_url || isValidUrl(formLegoComponent.redirect_url)) &&
    formComponentGroupIsValid(formLegoComponent.component_group);

export const formComponentGroupIsValid = (group: LegoComponentGroup) =>
    componentGroupHasQuestionOrInputComponents(group) &&
    formComponentGroupHasValidInputComponents(group) &&
    formComponentGroupHasValidQuestionComponents(group) &&
    !getInvalidComponentIdLegoComponentGroup(group, { parentIsForm: true });

export const componentGroupHasQuestionOrInputComponents = (group: LegoComponentGroup) =>
    group.rows.some(
        ({ component }) =>
            component.type === "question" || (component.type === "leaf" && component.leaf_component.type === "input"),
    );

export const formComponentGroupHasValidInputComponents = (group: LegoComponentGroup) => {
    let hasName = false;
    let hasEmail = false;
    let hasPhone = false;
    let hasPostalCode = false;
    for (const { component } of group.rows) {
        if (component.type === "leaf" && component.leaf_component.type === "input") {
            switch (component.leaf_component.input_component.type) {
                case "name":
                    if (hasName) {
                        return false;
                    }

                    hasName = true;
                    continue;
                case "email":
                    if (hasEmail) {
                        return false;
                    }

                    hasEmail = true;
                    continue;
                case "phone":
                    if (hasPhone) {
                        return false;
                    }

                    hasPhone = true;
                    continue;
                case "postal-code":
                    if (hasPostalCode) {
                        return false;
                    }

                    hasPostalCode = true;
                    continue;
                case "short-answer":
                    continue;
            }
        }
    }

    return true;
};

export const formComponentGroupHasValidQuestionComponents = (group: LegoComponentGroup) => {
    for (const { component: parentComponent } of group.rows) {
        if (parentComponent.type === "question") {
            if (parentComponent.question_component.component_group.rows.length === 0) {
                return false;
            }

            for (const { component } of parentComponent.question_component.component_group.rows) {
                if (
                    component.type !== "leaf" ||
                    component.leaf_component.type !== "option" ||
                    component.leaf_component.option_component.text === ""
                ) {
                    return false;
                }
            }
        }
    }

    return true;
};

export const isValidQuestionLegoComponent = (questionLegoComponent: QuestionLegoComponent) =>
    questionLegoComponent.component_group.rows.every(
        ({ component }) => component.type === "leaf" && component.leaf_component.type === "option",
    );

export const isValidEventLegoComponent = (eventLegoComponent: EventLegoComponent | HorizontalEventLegoComponent) => {
    return (
        !!eventLegoComponent.event &&
        !getInvalidComponentIdLegoComponentGroup(eventLegoComponent.component_group, { onlyLeaf: true })
    );
};

const isValidColumnLegoComponent = (columnLegoComponent: ColumnLegoComponent) => {
    if (!!columnLegoComponent.first_column) {
        const isValid = !getInvalidComponentIdLegoComponentGroup(columnLegoComponent.first_column);
        if (!isValid) {
            return false;
        }
    }

    if (!!columnLegoComponent.second_column) {
        const isValid = !getInvalidComponentIdLegoComponentGroup(columnLegoComponent.second_column);
        if (!isValid) {
            return false;
        }
    }

    if (!!columnLegoComponent.third_column) {
        const isValid = !getInvalidComponentIdLegoComponentGroup(columnLegoComponent.third_column);
        if (!isValid) {
            return false;
        }
    }

    return true;
};
