interface EnvironmentVariableConfig {
    twitterOAuth2Params: {
        clientId: string;
        clientSecret: string;
        redirectUri: string;
    };
    eventbriteOAuth2Params: {
        apiKey: string;
        clientSecret: string;
        redirectUri: string;
    };
    etixOAuth2Params: {
        clientId: string;
        clientSecret: string;
        redirectUri: string;
    };
    showclixLoginUrl: string;
    showclixEventBaseUrl: string;
}

interface Config {
    env: "development" | "staging" | "production";
    host: string;
    apiHost: string;
    webHost: string;
    trackingHost: string;
    stripeApiKey: string;
}

const envVariables = {
    twitterOAuth2Params: {
        clientId: process.env.TWITTER_OAUTH2_CLIENT_ID ?? "",
        clientSecret: process.env.TWITTER_OAUTH2_CLIENT_SECRET ?? "",
    },
    eventbriteOAuth2Params: {
        apiKey: process.env.EVENTBRITE_OAUTH2_API_KEY ?? "",
        clientSecret: process.env.EVENTBRITE_OAUTH2_CLIENT_SECRET ?? "",
    },
    etixOAuth2Params: {
        clientId: process.env.ETIX_OAUTH2_CLIENT_ID ?? "",
        clientSecret: process.env.ETIX_OAUTH2_CLIENT_SECRET ?? "",
        redirectUri: "https://manager.cymbal.co/oauth2/etix/callback",
    },
};

type Environment = "development" | "staging" | "production";

type ConfigRecord = Record<Environment, Config>;
type EnvironmentVariableConfigRecord = Record<Environment, EnvironmentVariableConfig>;

const envConfigRecord: EnvironmentVariableConfigRecord = {
    development: {
        ...envVariables,
        twitterOAuth2Params: {
            ...envVariables.twitterOAuth2Params,
            redirectUri: "http://localhost:3031/oauth2/twitter/callback",
        },
        eventbriteOAuth2Params: {
            ...envVariables.eventbriteOAuth2Params,
            redirectUri: "http://localhost:3031/oauth2/eventbrite/callback",
        },
        showclixLoginUrl: "https://admin-demo.showclix.com/api/registration",
        showclixEventBaseUrl: "https://www-demo.showclix.com/event",
    },
    staging: {
        ...envVariables,
        twitterOAuth2Params: {
            ...envVariables.twitterOAuth2Params,
            redirectUri: "https://staging-manager.cymbal.co/oauth2/twitter/callback",
        },
        eventbriteOAuth2Params: {
            ...envVariables.eventbriteOAuth2Params,
            redirectUri: "https://staging-manager.cymbal.co/oauth2/eventbrite/callback",
        },
        showclixLoginUrl: "https://admin-demo.showclix.com/api/registration",
        showclixEventBaseUrl: "https://www-demo.showclix.com/event",
    },
    production: {
        ...envVariables,
        twitterOAuth2Params: {
            ...envVariables.twitterOAuth2Params,
            redirectUri: "https://manager.cymbal.co/oauth2/twitter/callback",
        },
        eventbriteOAuth2Params: {
            ...envVariables.eventbriteOAuth2Params,
            redirectUri: "https://manager.cymbal.co/oauth2/eventbrite/callback",
        },
        showclixLoginUrl: "https://admin.showclix.com/api/registration",
        showclixEventBaseUrl: "https://www.showclix.com/event",
    },
};

const configRecord: ConfigRecord = {
    development: {
        env: "development",
        host: "http://localhost:3031",
        apiHost: "http://localhost:8080",
        webHost: "http://localhost:3030",
        trackingHost: "http://localhost:8081",
        stripeApiKey:
            "pk_test_51LKSx9DUFJHrciOV6vQAOVOCPJWBZOPIQZ7MqDykKdRFk5oRGOc1OCZOzo3kIPJKr6tVr3jOJefmKZmiSMRPJtwq007p0vVP57",
    },
    staging: {
        env: "staging",
        host: "https://staging-manager.cymbal.co",
        apiHost: "https://staging-api.cymbal.co",
        webHost: "https://staging.cymbal.co",
        trackingHost: "https://staging.cy.link",
        stripeApiKey:
            "pk_test_51LKSx9DUFJHrciOV6vQAOVOCPJWBZOPIQZ7MqDykKdRFk5oRGOc1OCZOzo3kIPJKr6tVr3jOJefmKZmiSMRPJtwq007p0vVP57",
    },
    production: {
        env: "production",
        host: "https://manager.cymbal.co",
        apiHost: "https://api.cymbal.co",
        webHost: "https://cymbal.co",
        trackingHost: "https://cy.link",
        stripeApiKey:
            "pk_live_51LKSx9DUFJHrciOVruTpEgzJ8HU2rnpfUoBvSFrwULMpRCzLoVaEeLI4crV4GhykMN2cgNAmmVhLbXK2rMiBIASp00HdegT6uO",
    },
};

const getEnv = () => {
    switch (process.env.NEXT_PUBLIC_ENV) {
        case "production":
        case "staging":
        case "development":
            return process.env.NEXT_PUBLIC_ENV;
        default:
            return "development";
    }
};

export const config = configRecord[getEnv()] ?? configRecord["development"];
export const envConfig = envConfigRecord[getEnv()] ?? envConfigRecord["development"];
