import clsx from "clsx";
import React from "react";

import { makeStyles } from "../../styles/makeStyles";

const useStyles = makeStyles<{
    color: string | undefined;
    fontSize: string | undefined;
    backgroundColor: string | undefined;
}>()((theme, { color, backgroundColor, fontSize = "1rem" }) => ({
    inputWrapper: {
        position: "relative",
    },
    hiddenInputText: {
        position: "absolute",
        visibility: "hidden",
        fontWeight: 300,
        fontSize,
    },
    input: {
        border: "none",
        color: color ?? theme.palette.text.primary,
        ...(backgroundColor !== undefined ? { backgroundColor } : { background: "none" }),
        fontFamily: "Lexend",
        fontWeight: 300,
        fontSize,
        margin: 0,
        lineHeight: 1.5,
        "&::placeholder": {
            color: theme.palette.midGray,
        },
        "&:focus": {
            outline: "none",
        },
    },
}));

interface BaseProps {
    className?: string;
    disabled?: boolean;
    inputClassName?: string;
    minWidth?: number;
    sidePadding?: number;
    value?: string | number;
    inputRef?: React.RefObject<HTMLInputElement>;
    autoFocus?: boolean;
    color?: string;
    fontSize?: string;
    backgroundColor?: string;
    placeholder?: string;
}

type Props = BaseProps & React.HTMLAttributes<HTMLInputElement>;

const MIN_INPUT_WIDTH = 70;

const SIDE_PADDING = 8;

export const ResizableInput = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            className,
            inputClassName,
            minWidth = MIN_INPUT_WIDTH,
            sidePadding = SIDE_PADDING,
            value,
            inputRef,
            color,
            fontSize,
            backgroundColor,
            disabled = false,
            autoFocus = false,
            ...rest
        },
        ref,
    ) => {
        const { classes } = useStyles({ color, fontSize, backgroundColor });

        const [width, setWidth] = React.useState(minWidth);

        const spanRef = React.createRef<HTMLSpanElement>();
        const localInputRef = React.createRef<HTMLInputElement>();
        const effectiveInputRef = inputRef ?? localInputRef;

        React.useEffect(
            () =>
                setWidth(
                    spanRef.current?.offsetWidth
                        ? Math.max(spanRef.current?.offsetWidth + sidePadding, minWidth)
                        : minWidth,
                ),
            [value, spanRef, minWidth, sidePadding],
        );

        React.useEffect(() => {
            if (autoFocus) {
                effectiveInputRef.current?.focus();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [autoFocus]);

        return (
            <div className={clsx(className, classes.inputWrapper)} ref={ref}>
                <span ref={spanRef} className={classes.hiddenInputText}>
                    {value}
                </span>
                <input
                    className={clsx(inputClassName, classes.input)}
                    disabled={disabled}
                    style={{ width }}
                    ref={effectiveInputRef}
                    value={value}
                    {...rest}
                />
            </div>
        );
    },
);
