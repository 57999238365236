import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import React from "react";
import { HexAlphaColorPicker } from "react-colorful";

import { CymbalInput, CymbalInputType } from "../../../../shared/components/cymbalInput";
import { makeStyles } from "../../../../shared/styles/makeStyles";
import { ColorPickerInput } from "./colorPickerInput";

const useStyles = makeStyles()((theme) => ({
    pickerSection: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
    },
    popper: {
        paddingTop: theme.spacing(1),
        zIndex: theme.zIndex.modal,
        background: theme.palette.background.paper,
    },
    hexColorPicker: {
        "&.react-colorful": {
            width: "auto",
            ".react-colorful__saturation": {
                borderRadius: theme.spacing(1),
                marginBottom: theme.spacing(2),
            },

            ".react-colorful__hue": {
                height: 12,
                marginBottom: theme.spacing(1.5),
                borderRadius: 6,
            },

            ".react-colorful__alpha": {
                height: 12,
                borderRadius: 6,
            },

            ".react-colorful__pointer": {
                width: 16,
                height: 16,
            },

            marginBottom: theme.spacing(2),
        },
    },
    hexPercentSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

interface Props {
    className?: string;
    label: string;
    color: string;
    inputType: CymbalInputType;
    setColor: (color: string) => void;
}

export const ColorPicker: React.FC<Props> = React.memo(({ className, color, label, inputType, setColor }) => {
    const { classes } = useStyles();

    const [resetSelectionStart, setResetSelectionStart] = React.useState<number>();
    const [resetHexSelectionStart, setResetHexSelectionStart] = React.useState<number>();
    const [popperElement, setPopperElement] = React.useState<HTMLDivElement>();
    const [isShowingColors, setIsShowingColors] = React.useState(false);

    const inputRef = React.createRef<HTMLInputElement>();
    const hexInputRef = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
        if (resetSelectionStart !== undefined) {
            inputRef.current?.setSelectionRange(resetSelectionStart, resetSelectionStart);
        }
    }, [resetSelectionStart, inputRef]);

    React.useEffect(() => {
        if (resetHexSelectionStart !== undefined) {
            hexInputRef.current?.setSelectionRange(resetHexSelectionStart, resetHexSelectionStart);
        }
    }, [resetHexSelectionStart, hexInputRef]);

    const alphaPercent = getPercentAlpha(color);

    const onChangeColor = React.useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            const newHexColor = `#${e.currentTarget.value.replace(/[^0-9a-fA-F]/g, "").slice(0, 6)}`;

            const hexAlpha = color.slice(7, 9);
            const newColor =
                newHexColor.length === 7 && hexAlpha.length === 2 ? `${newHexColor}${hexAlpha}` : newHexColor;

            setColor(newColor.toLocaleUpperCase());
            setResetHexSelectionStart(e.currentTarget.selectionStart ?? undefined);
        },
        [color, setColor],
    );

    const onSelectColor = React.useCallback((e: React.SyntheticEvent<HTMLInputElement, Event>) => {
        if (e.currentTarget.selectionStart === 0 && e.currentTarget.selectionEnd === 0) {
            e.currentTarget.setSelectionRange(1, 1);
        }
    }, []);

    const onChangeAlpha = React.useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            const inputtedPercentValue = parseInt(e.currentTarget.value.replace(/[^0-9]/g, ""));
            const newPercentValue = isNaN(inputtedPercentValue) ? 0 : Math.max(Math.min(inputtedPercentValue, 100), 0);

            const colorBase = color.slice(0, 7);
            const hexRGB = colorBase.length < 7 ? `${colorBase}${"F".repeat(7 - colorBase.length)}` : colorBase;
            const hexAlpha = getHexAlpha(isNaN(newPercentValue) ? 0 : newPercentValue);
            setColor(`${hexRGB}${hexAlpha}`);
        },
        [color, setColor],
    );

    const onSelectAlpha = React.useCallback((e: React.SyntheticEvent<HTMLInputElement, Event>) => {
        const valueLength = e.currentTarget.value.length;
        if (e.currentTarget.selectionStart === valueLength && e.currentTarget.selectionEnd === valueLength) {
            e.currentTarget.setSelectionRange(valueLength - 1, valueLength - 1);
        }
    }, []);

    return (
        <ClickAwayListener onClickAway={() => setIsShowingColors(false)} mouseEvent="onMouseDown">
            <div className={className}>
                <CymbalInput
                    ref={(ref) => setPopperElement(ref ?? undefined)}
                    label={label}
                    inputRef={inputRef}
                    inputType={inputType}
                    value={`#${color.slice(1, 9)}`}
                    onChange={(e) => {
                        setColor(`#${e.target.value.replace(/[^0-9a-fA-F]/g, "").toLocaleUpperCase()}`);
                        setResetSelectionStart(e.target.selectionStart ?? undefined);
                    }}
                    onSelect={(e) => {
                        if (e.currentTarget.selectionStart === 0 && e.currentTarget.selectionEnd === 0) {
                            e.currentTarget.setSelectionRange(1, 1);
                        }
                    }}
                    onFocus={() => setIsShowingColors(true)}
                />
                <Popper
                    style={{ width: popperElement?.clientWidth }}
                    className={classes.popper}
                    anchorEl={popperElement}
                    open={isShowingColors}
                    placement="bottom"
                >
                    <div className={classes.pickerSection}>
                        <HexAlphaColorPicker
                            className={classes.hexColorPicker}
                            color={color}
                            onChange={(color) => setColor(color.toLocaleUpperCase())}
                        />
                        <div className={classes.hexPercentSection}>
                            <ColorPickerInput
                                inputRef={hexInputRef}
                                value={`#${color.slice(1, 7)}`}
                                onChange={onChangeColor}
                                onSelect={onSelectColor}
                            />
                            <ColorPickerInput
                                value={`${alphaPercent}%`}
                                onChange={onChangeAlpha}
                                onSelect={onSelectAlpha}
                            />
                        </div>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
});

export const getPercentAlpha = (color: string) => {
    const hexAlphaElement = color.slice(7, 9);
    if (hexAlphaElement.length !== 2) {
        return 100;
    }

    const hexNumber = parseInt(hexAlphaElement, 16);

    return Math.round((hexNumber * 100) / 255);
};

export const getHexAlpha = (num: number) => {
    const hexRaw = (Math.round((num * 255) / 100) % 256).toString(16).padStart(2).toLocaleUpperCase();
    return hexRaw == "FF" ? "" : hexRaw;
};
