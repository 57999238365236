import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import React from "react";

import { CloseIcon } from "../icons/closeIcon";
import { LeftChevronIcon } from "../icons/leftChevronIcon";
import { makeStyles } from "../styles/makeStyles";
import { TextButton } from "./textButton";

const useStyles = makeStyles<{ fullScreenBreakpoint: number }>()((theme, { fullScreenBreakpoint }) => ({
    dialogPaperSmall: {
        maxWidth: 416,
    },
    dialogPaperMedium: {
        maxWidth: 848,
    },
    dialogPaperLarge: {
        maxWidth: 1000,
    },
    dialogPaperXL: {
        maxWidth: 1200,
    },
    dialogPaper: {
        [`@media (max-width: ${fullScreenBreakpoint}px)`]: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 0,
            height: "100%",
            width: "100%",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: "unset",
            maxWidth: "unset",
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(-3),
        fontWeight: 300,
        "&.dialog-title-border": {
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: 0,
        },
    },
    titleSection: {
        textAlign: "center",
    },
    subtitle: {
        fontWeight: 300,
        fontSize: "0.75rem",
        color: theme.palette.midGray,
    },
    closeButton: {
        color: "#FFFFFF",
        padding: 0,
        width: theme.spacing(5),
        height: theme.spacing(5),
        "&.Mui-disabled": {
            color: theme.palette.midGray,
        },
    },
    hidden: {
        visibility: "hidden",
    },
    backIcon: {
        fontSize: 24,
    },
    closeIcon: {
        fontSize: 24,
    },
}));

export type DialogCloseReason = "backdropClick" | "escapeKeyDown" | "closeButton";

interface Props extends Omit<DialogProps, "onClose" | "maxWidth" | "classes" | "fullWidth"> {
    className?: string;
    paperClassName?: string;
    titleClassName?: string;
    isCloseHidden?: boolean;
    isCloseDisabled?: boolean;
    title?: string;
    subtitle?: string;
    actionButton?: {
        isDisabled?: boolean;
        title: string;
        onClick: () => void;
    };
    noTitleBorder?: boolean;
    size?: "small" | "medium" | "large" | "xl";
    useBackButton?: boolean;
    fullScreenBreakpoint?: number;
    onBackClicked?: () => void;
    onClose?: (reason: DialogCloseReason) => void;
}

export const CymbalDialog: React.FC<Props> = React.memo(
    ({
        className,
        paperClassName,
        titleClassName,
        isCloseHidden = false,
        isCloseDisabled = false,
        title,
        subtitle,
        actionButton,
        noTitleBorder = false,
        size = "small",
        useBackButton = false,
        fullScreenBreakpoint = 744,
        children,
        onClose,
        onBackClicked,
        ...dialogProps
    }) => {
        const { classes } = useStyles({ fullScreenBreakpoint });

        const internalOnClose = React.useCallback(
            (_: object, reason: "backdropClick" | "escapeKeyDown") => {
                if (useBackButton && (reason === "backdropClick" || reason === "escapeKeyDown")) {
                    return;
                }

                if (onClose && !isCloseHidden && !isCloseDisabled) {
                    onClose(reason);
                }
            },
            [useBackButton, isCloseDisabled, isCloseHidden, onClose],
        );

        const onCloseClicked = React.useCallback(() => {
            if (useBackButton) {
                onBackClicked?.();
            } else {
                onClose?.("closeButton");
            }
        }, [useBackButton, onBackClicked, onClose]);

        return (
            <Dialog
                className={className}
                classes={{
                    paper: clsx(
                        paperClassName,
                        classes.dialogPaper,
                        size === "small"
                            ? classes.dialogPaperSmall
                            : size === "medium"
                              ? classes.dialogPaperMedium
                              : size === "large"
                                ? classes.dialogPaperLarge
                                : classes.dialogPaperXL,
                    ),
                }}
                transitionDuration={0}
                onClose={internalOnClose}
                fullWidth
                {...dialogProps}
            >
                {(!isCloseHidden || !!title || !!actionButton) && (
                    <DialogTitle
                        className={clsx(
                            classes.dialogTitle,
                            "cymbal-dialog-title",
                            noTitleBorder || !title ? undefined : "dialog-title-border",
                            titleClassName,
                        )}
                    >
                        {isCloseHidden ? (
                            !!actionButton ? (
                                <TextButton className={classes.hidden}>{actionButton.title}</TextButton>
                            ) : (
                                <div />
                            )
                        ) : (
                            <IconButton
                                className={classes.closeButton}
                                onClick={onCloseClicked}
                                disabled={isCloseDisabled}
                                size="large"
                            >
                                {useBackButton ? (
                                    <LeftChevronIcon className={classes.backIcon} />
                                ) : (
                                    <CloseIcon className={classes.closeIcon} />
                                )}
                            </IconButton>
                        )}

                        {!!title && (
                            <div className={classes.titleSection}>
                                <div>{title}</div>
                                {!!subtitle && <div className={classes.subtitle}>{subtitle}</div>}
                            </div>
                        )}
                        {!!actionButton ? (
                            <TextButton onClick={actionButton.onClick} color="primary">
                                {actionButton.title}
                            </TextButton>
                        ) : isCloseHidden ? (
                            <div />
                        ) : (
                            <IconButton className={clsx(classes.closeButton, classes.hidden)} size="large">
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        )}
                    </DialogTitle>
                )}

                {children}
            </Dialog>
        );
    },
);
