import clsx from "clsx";
import React from "react";

import { CircleColorPicker } from "../../../../../src/settingsPage/embedComponents/colorPicker/circleColorPicker";
import { AppContext } from "../../../../state/appContext";
import { makeStyles } from "../../../../styles/makeStyles";

const useStyles = makeStyles()(() => ({
    row: {
        display: "flex",
        gap: 12,
    },
    colorCircleInner: {
        borderRadius: "50%",
        outline: "1px solid rgba(255, 255, 255, .2)",
        height: 25,
        width: 25,
    },
    colorCircle: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        height: 25,
        width: 25,
    },
    colorCircleSelected: {
        outline: "2px solid white",
    },
}));

export const unchangingColors = ["#000000", "#FFFFFF"];

const colorPresets = ["#D3D3D3", "#ADD8E6", "#F5F5DC", "#FFB6C1", "#98FF98"];

interface Props {
    color: string;
    setColor: (newColor: string) => void;
    className?: string;
}

export const ColorRowSelector = React.memo(({ color, className, setColor }: Props) => {
    const { customColors } = React.useContext(AppContext);
    const { classes } = useStyles();

    const rowRef = React.createRef<HTMLDivElement>();

    const colorsToShow = React.useMemo(
        () => [...unchangingColors, ...customColors, ...colorPresets].slice(0, 7),
        [customColors],
    );

    return (
        <div className={className}>
            <div className={classes.row}>
                <div
                    className={clsx(
                        classes.colorCircle,
                        !colorsToShow.includes(color) ? classes.colorCircleSelected : "",
                    )}
                    ref={rowRef}
                >
                    <CircleColorPicker color={color} setColor={setColor} />
                </div>
                {colorsToShow.map((colorHex) => (
                    <div
                        className={clsx(classes.colorCircle, color === colorHex ? classes.colorCircleSelected : "")}
                        key={colorHex}
                        onClick={() => {
                            setColor(colorHex);
                        }}
                    >
                        <div className={classes.colorCircleInner} style={{ backgroundColor: colorHex }} />
                    </div>
                ))}
            </div>
        </div>
    );
});
