import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import React from "react";
import { HexAlphaColorPicker, HexColorPicker } from "react-colorful";

import { AppContext } from "../../../../shared/state/appContext";
import { makeStyles } from "../../../../shared/styles/makeStyles";
import { stopPropagation, stopPropagationAndDefault } from "../../../../shared/util/handlers";
import { getHexAlpha, getPercentAlpha } from "./colorPicker";
import { ColorPickerInput } from "./colorPickerInput";

const useStyles = makeStyles<{ size?: number; popperWidth: number; backgroundColor?: string }>()(
    (theme, { size, popperWidth, backgroundColor }) => ({
        colorCircleInner: {
            borderRadius: 100,
            outline: "1px solid rgba(255, 255, 255, .2)",
            height: size,
            width: size,
            cursor: "pointer",
            background:
                backgroundColor ??
                "conic-gradient(from 180deg at 50% 50%, #66A0F8 0deg, #DCFD95 108.75deg, #E88C61 260.62deg, #6AA3F6 360deg)",
        },
        pickerSection: {
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
            border: `1px solid ${theme.palette.divider}`,
            background: theme.palette.background.paper,
        },
        popper: {
            paddingTop: theme.spacing(1),
            zIndex: theme.zIndex.modal,
            width: popperWidth,
        },
        hexColorPicker: {
            "&.react-colorful": {
                width: "auto",
                ".react-colorful__saturation": {
                    borderRadius: theme.spacing(1),
                    marginBottom: theme.spacing(2),
                },

                ".react-colorful__hue": {
                    height: 12,
                    marginBottom: theme.spacing(1.5),
                    borderRadius: 6,
                },

                ".react-colorful__alpha": {
                    height: 12,
                    borderRadius: 6,
                },

                ".react-colorful__pointer": {
                    width: 16,
                    height: 16,
                },

                marginBottom: theme.spacing(2),
            },
        },
        hexPercentSection: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
    }),
);

const popperModifiers: any = [
    {
        name: "offset",
        options: {
            offset: [0, 8],
        },
    },
];

interface Props {
    className?: string;
    color: string;
    backgroundColor?: string;
    anchorEl?: HTMLElement | null;
    popperWidth?: number;
    popperPlacement?: PopperPlacementType;
    size?: number;
    alphaIsEnabled?: boolean;
    onCloseColorPickerPopper?: () => void;
    setColor: (color: string) => void;
}

export const CircleColorPicker: React.FC<Props> = React.memo(
    ({
        className,
        color,
        backgroundColor,
        anchorEl,
        popperWidth = 200,
        popperPlacement = "bottom-start",
        size = 25,
        alphaIsEnabled = false,
        onCloseColorPickerPopper,
        setColor,
    }) => {
        const { saveCustomColor } = React.useContext(AppContext);

        const [inputColor, setInputColor] = React.useState(color);

        const { classes } = useStyles({ size, popperWidth, backgroundColor });
        const [popperElement, setPopperElement] = React.useState<HTMLDivElement>();

        const [resetHexSelectionStart, setResetHexSelectionStart] = React.useState<number>();
        const [isShowingColors, setIsShowingColors] = React.useState(false);

        const [hasOpenedSelector, setHasOpenedSelector] = React.useState(false);

        React.useEffect(() => {
            if (!isShowingColors && hasOpenedSelector) {
                saveCustomColor(color);
            }
        }, [isShowingColors, color, hasOpenedSelector, saveCustomColor]);

        const hexInputRef = React.createRef<HTMLInputElement>();

        React.useEffect(() => {
            if (resetHexSelectionStart !== undefined) {
                hexInputRef.current?.setSelectionRange(resetHexSelectionStart, resetHexSelectionStart);
            }
        }, [resetHexSelectionStart, hexInputRef]);

        const alphaPercent = getPercentAlpha(color);

        const hideColors = React.useCallback(() => {
            setIsShowingColors(false);
            onCloseColorPickerPopper?.();
        }, [onCloseColorPickerPopper]);

        const toggleShowColors = React.useCallback(() => {
            setIsShowingColors((prev) => {
                if (prev) {
                    onCloseColorPickerPopper?.();
                }
                return !prev;
            });
            setHasOpenedSelector((prev) => !prev);
        }, [onCloseColorPickerPopper]);

        const onChangeColor = React.useCallback(
            (e: React.FormEvent<HTMLInputElement>) => {
                const newHexColor = `#${e.currentTarget.value.replace(/[^0-9a-fA-F]/g, "").slice(0, 6)}`;

                const hexAlpha = color.slice(7, 9);
                const newColor =
                    newHexColor.length === 7 && hexAlpha.length === 2 ? `${newHexColor}${hexAlpha}` : newHexColor;

                setInputColor(newColor);
                if (newColor.length >= 7) {
                    setColor(newColor.toLocaleUpperCase());
                    setResetHexSelectionStart(e.currentTarget.selectionStart ?? undefined);
                }
            },
            [color, setColor],
        );

        const onSelectColor = React.useCallback((e: React.SyntheticEvent<HTMLInputElement, Event>) => {
            if (e.currentTarget.selectionStart === 0 && e.currentTarget.selectionEnd === 0) {
                e.currentTarget.setSelectionRange(1, 1);
            }
        }, []);

        const onChangeAlpha = React.useCallback(
            (e: React.FormEvent<HTMLInputElement>) => {
                const inputtedPercentValue = parseInt(e.currentTarget.value.replace(/[^0-9]/g, ""));
                const newPercentValue = isNaN(inputtedPercentValue)
                    ? 0
                    : Math.max(Math.min(inputtedPercentValue, 100), 0);

                const colorBase = color.slice(0, 7);
                const hexRGB = colorBase.length < 7 ? `${colorBase}${"F".repeat(7 - colorBase.length)}` : colorBase;
                const hexAlpha = getHexAlpha(isNaN(newPercentValue) ? 0 : newPercentValue);
                setColor(`${hexRGB}${hexAlpha}`);
                setInputColor(`${hexRGB}${hexAlpha}`);
            },
            [color, setColor],
        );

        const onSelectAlpha = React.useCallback((e: React.SyntheticEvent<HTMLInputElement, Event>) => {
            const valueLength = e.currentTarget.value.length;
            if (e.currentTarget.selectionStart === valueLength && e.currentTarget.selectionEnd === valueLength) {
                e.currentTarget.setSelectionRange(valueLength - 1, valueLength - 1);
            }
        }, []);

        const onChangeHex = React.useCallback(
            (newColor: string) => {
                setColor(newColor.toLocaleUpperCase());
                setInputColor(newColor.toLocaleUpperCase());
            },
            [setColor],
        );

        return (
            <ClickAwayListener onClickAway={hideColors} mouseEvent="onMouseDown">
                <div className={className}>
                    <div
                        className={classes.colorCircleInner}
                        ref={(ref) => setPopperElement(ref ?? undefined)}
                        onClick={toggleShowColors}
                        onMouseDown={stopPropagationAndDefault}
                    />
                    <Popper
                        className={classes.popper}
                        anchorEl={anchorEl ?? popperElement}
                        open={isShowingColors}
                        placement={popperPlacement}
                        modifiers={popperModifiers}
                    >
                        <div className={classes.pickerSection} onMouseDown={stopPropagationAndDefault}>
                            {alphaIsEnabled ? (
                                <HexAlphaColorPicker
                                    className={classes.hexColorPicker}
                                    color={color}
                                    onChange={onChangeHex}
                                />
                            ) : (
                                <HexColorPicker
                                    className={classes.hexColorPicker}
                                    color={color}
                                    onChange={onChangeHex}
                                />
                            )}
                            <div className={classes.hexPercentSection}>
                                <ColorPickerInput
                                    inputRef={hexInputRef}
                                    value={`#${inputColor.slice(1, 7)}`}
                                    onMouseDown={stopPropagation}
                                    onChange={onChangeColor}
                                    onSelect={onSelectColor}
                                />
                                {alphaIsEnabled && (
                                    <ColorPickerInput
                                        value={`${alphaPercent}%`}
                                        onMouseDown={stopPropagation}
                                        onChange={onChangeAlpha}
                                        onSelect={onSelectAlpha}
                                    />
                                )}
                            </div>
                        </div>
                    </Popper>
                </div>
            </ClickAwayListener>
        );
    },
);
