import createTheme from "@mui/material/styles/createTheme";

import { TableSortChevron } from "./tableSortChevron";

const white = "#ffffff";
const black = "#000000";

const baseTheme = createTheme({
    palette: {
        background: {
            default: black,
            paper: "#131416",
            highlight: "rgba(255, 255, 255, 0.1)",
            paperHighlight: "#1c1d1f",
            accentHighlight: "#333333",
            paperDoubleHighlight: "#27282A",
        },
        text: {
            primary: white,
            secondary: black,
        },
        primary: {
            main: white,
        },
        secondary: {
            main: "#333333",
        },
        divider: "#2c2c2c",
        error: {
            main: "#b24125",
        },
        warning: {
            main: "#ffaf0f",
        },
        accentYellow: "#f0ef79",
        accentBlue: "#4f75f6",
        accentOrange: "#b24125",
        midGray: "#808080",
        accentGray: "#adadad",
        linkBlue: "#1c98f3",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 500,
            md: 800,
            lg: 1200,
            xl: 1550,
        },
    },
    typography: {
        h1: {
            fontFamily: "Lexend",
            fontWeight: 500,
            fontSize: "3.8rem",
        },
        h2: {
            fontFamily: "Lexend",
            fontWeight: 500,
            fontSize: "3rem",
        },
        h3: {
            fontFamily: "Lexend",
            fontWeight: 500,
            fontSize: "2.5rem",
        },
        h4: {
            fontFamily: "Lexend",
            fontWeight: 500,
            fontSize: "2rem",
        },
        h5: {
            fontFamily: "Lexend",
            fontWeight: 500,
            fontSize: "1.7rem",
        },
        h6: {
            fontFamily: "Lexend",
            fontWeight: 500,
            fontSize: "1.4rem",
        },
        body1: {
            fontFamily: "Lexend",
            fontSize: "0.875rem",
        },
        body2: {
            fontFamily: "Lexend",
            fontSize: "0.75rem",
        },
    },
});

const inputAutoFillClasses =
    "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active";

export const theme = createTheme({
    ...baseTheme,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    scrollbarColor: baseTheme.palette.background.highlight,
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "transparent",
                        width: 10,
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 7,
                        backgroundColor: baseTheme.palette.background.highlight,
                        minHeight: "unset",
                        backgroundClip: "padding-box",
                        border: "3px solid transparent",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus, &::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active, &::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                        {
                            borderRadius: 7,
                            backgroundColor: baseTheme.palette.background.highlight,
                            minHeight: "unset",
                            backgroundClip: "padding-box",
                            border: "3px solid transparent",
                        },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        backgroundColor: "transparent",
                    },
                },
                body: {
                    backgroundColor: baseTheme.palette.background.default,
                    fontSize: "1rem",
                    fontFamily: "Lexend",
                    overflowY: "hidden",
                    letterSpacing: "normal",
                },
                a: {
                    color: baseTheme.palette.text.primary,
                    textDecoration: "none",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: `${baseTheme.spacing(1.5)} ${baseTheme.spacing(3)}`,
                    borderRadius: baseTheme.spacing(1),
                    ".MuiButtonBase-root.MuiIconButton-root": {
                        width: 34,
                        height: 34,
                    },
                },
                message: {
                    fontFamily: "Lexend",
                    fontSize: "0.875rem",
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                },
                filledInfo: {
                    backgroundColor: baseTheme.palette.primary.main,
                    color: baseTheme.palette.text.secondary,
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    "&.MuiSlider-thumb": {
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    fontFamily: "Lexend",
                    fontWeight: 500,
                    fontSize: "1rem",
                    textTransform: "none",
                    height: baseTheme.spacing(6.5),
                    borderRadius: baseTheme.spacing(3.25),
                    padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(2.5)}`,
                    "&.MuiButton-outlinedError": {
                        border: `1px solid ${baseTheme.palette.error.main}`,
                    },
                    "&.MuiButton-outlinedError.Mui-disabled": {
                        border: `1px solid ${baseTheme.palette.error.main}`,
                    },

                    "&:hover": {
                        boxShadow: "none",
                    },
                    letterSpacing: "normal",
                },
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: "#D2D2D2",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: baseTheme.palette.midGray,
                        color: "rgba(0, 0, 0, 0.5)",
                    },
                },
                containedSecondary: {
                    "&:hover": {
                        backgroundColor: "#262626",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "rgba(51, 51, 51, 0.8)",
                        color: baseTheme.palette.midGray,
                    },
                },
                // TODO: We want to update our secondary button color but don't want to break existing places
                // For now, let's override warning and use warning.
                containedWarning: {
                    backgroundColor: baseTheme.palette.background.paper,
                    color: baseTheme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: baseTheme.palette.background.paper,
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "rgba(19, 20, 22, 0.5)",
                        color: baseTheme.palette.midGray,
                    },
                },
                containedInfo: {
                    backgroundColor: baseTheme.palette.background.paperHighlight,
                    color: baseTheme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: baseTheme.palette.background.paperHighlight,
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "rgba(28, 29, 31, 0.5)",
                        color: baseTheme.palette.midGray,
                    },
                },
                containedError: {
                    backgroundColor: "#FF0000",
                    "&:hover": {
                        backgroundColor: "#C40000",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "rgba(255, 0, 0, 0.8)",
                        color: "rgba(255, 255, 255, 0.7)",
                    },
                },
                outlinedPrimary: {
                    borderColor: white,
                    "&:hover": {
                        borderColor: "rgba(255, 255, 255, 0.8)",
                    },
                    "&.Mui-disabled": {
                        borderColor: "white",
                        color: "white",
                        opacity: 0.5,
                    },
                },
                textPrimary: {
                    "&:hover": {
                        opacity: 0.5,
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: 0,
                    borderRadius: 0,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    ".MuiSvgIcon-root": {
                        fontSize: "1.75em",
                    },
                },
                colorPrimary: {
                    color: "rgba(255, 255, 255, 0.8)",
                },
            },
        },
        MuiChip: {
            defaultProps: {
                color: "primary",
            },
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontFamily: "Lexend",
                    padding: `${baseTheme.spacing(2)} ${baseTheme.spacing(1.5)}`,
                    height: 52,
                    borderRadius: 26,
                },
                filledPrimary: {
                    "&:hover": {
                        backgroundColor: baseTheme.palette.primary.main,
                    },
                },
                outlinedPrimary: {
                    border: `1px solid ${baseTheme.palette.midGray}`,
                },
                colorError: {
                    color: "inherit",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: baseTheme.spacing(5),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: `1px solid ${baseTheme.palette.divider}`,
                    padding: baseTheme.spacing(3),
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: baseTheme.spacing(4),
                    padding: baseTheme.spacing(3),
                    fontSize: "1rem",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontFamily: "Lexend",
                    fontSize: "1rem",
                    height: baseTheme.spacing(8),
                    position: "relative",
                    "&.cymbal-dialog-title+.MuiDialogContent-root": {
                        paddingTop: baseTheme.spacing(3),
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    marginRight: 0,
                    ".MuiButtonBase-root.MuiRadio-root": {
                        marginRight: baseTheme.spacing(1),
                    },
                    "&.Mui-disabled": {
                        pointerEvents: "none",
                        ".MuiButtonBase-root.MuiRadio-root": {
                            color: baseTheme.palette.midGray,
                        },
                        ".MuiTypography-root.MuiFormControlLabel-label": {
                            color: baseTheme.palette.midGray,
                        },
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontFamily: "Lexend",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    width: baseTheme.spacing(5),
                    height: baseTheme.spacing(5),
                    color: baseTheme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
                colorPrimary: {
                    "&.Mui-disabled": {
                        color: baseTheme.palette.midGray,
                    },
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
                colorSecondary: {
                    backgroundColor: baseTheme.palette.secondary.main,
                    "&:hover": {
                        backgroundColor: "#262626",
                    },
                    "&.Mui-disabled": {
                        color: baseTheme.palette.midGray,
                        backgroundColor: "#262626",
                    },
                },
                // TODO: We want to update our secondary button color but don't want to break existing places
                // For now, let's override warning and use warning.
                colorWarning: {
                    color: baseTheme.palette.text.primary,
                    backgroundColor: baseTheme.palette.background.highlight,
                    "&:hover": {
                        backgroundColor: baseTheme.palette.background.highlight,
                    },
                    "&.Mui-disabled": {
                        backgroundColor: baseTheme.palette.background.highlight,
                        opacity: 0.5,
                    },
                },
                colorInfo: {
                    color: baseTheme.palette.text.primary,
                    backgroundColor: baseTheme.palette.background.paperHighlight,
                    "&:hover": {
                        backgroundColor: baseTheme.palette.background.paperHighlight,
                    },
                    "&.Mui-disabled": {
                        backgroundColor: baseTheme.palette.background.paperHighlight,
                        opacity: 0.5,
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    paddingTop: baseTheme.spacing(1.5),
                    paddingBottom: baseTheme.spacing(1.5),
                },
                paper: {
                    borderRadius: baseTheme.spacing(2),
                    border: `1px solid ${baseTheme.palette.divider}`,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "Lexend",
                    fontWeight: 300,
                    fontSize: "0.875rem",
                    padding: `${baseTheme.spacing(1.5)} ${baseTheme.spacing(3)}`,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "&.Mui-focusVisible": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    ".MuiFormControlLabel-root:not(:last-of-type)": {
                        marginBottom: baseTheme.spacing(1),
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: baseTheme.spacing(1),
                    fontSize: "1rem",
                    "&:hover:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255, 255, 255, 0.6)",
                    },
                    "&.Mui-focused:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
                        borderColor: white,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(255, 255, 255, 0.3)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: 1,
                    },
                },
                input: {
                    padding: "23.5px 14px",
                    "&.Mui-disabled": {
                        WebkitTextFillColor: "rgba(255, 255, 255, 0.3)",
                    },
                },
                notchedOutline: {
                    borderColor: baseTheme.palette.midGray,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    width: baseTheme.spacing(3),
                    height: baseTheme.spacing(3),
                },
                colorPrimary: {
                    color: baseTheme.palette.primary.main,
                    "&.Mui-disabled": {
                        color: baseTheme.palette.midGray,
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    [baseTheme.breakpoints.up("sm")]: {
                        minWidth: 480,
                    },
                },
                anchorOriginBottomCenter: {
                    [baseTheme.breakpoints.up("sm")]: {
                        bottom: 40,
                    },
                    [baseTheme.breakpoints.down("sm")]: {
                        bottom: 24,
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    ".cymbal-table-cell": {
                        fontFamily: "Lexend",
                        fontSize: "0.75rem",
                        color: baseTheme.palette.midGray,
                        lineHeight: "1.5rem",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    padding: `${baseTheme.spacing(3)} ${baseTheme.spacing(1)}`,
                    borderBottom: "none",
                    fontFamily: "Lexend",
                    fontWeight: 300,
                },
                head: {
                    fontSize: "0.75rem",
                    color: baseTheme.palette.midGray,
                },
                alignRight: {
                    paddingRight: baseTheme.spacing(2),
                    ".MuiTableSortLabel-root": {
                        flexDirection: "row",
                        marginRight: baseTheme.spacing(-2.5),
                    },
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                actions: {
                    ".Mui-disabled": {
                        color: baseTheme.palette.midGray,
                    },
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: "inherit",
                    "&:hover": {
                        color: baseTheme.palette.primary.main,
                        ".MuiTableSortLabel-icon": {
                            color: baseTheme.palette.primary.main,
                        },
                    },
                    "&:focus": {
                        color: "inherit",
                        "&:hover": {
                            color: baseTheme.palette.primary.main,
                            ".MuiTableSortLabel-icon": {
                                color: baseTheme.palette.primary.main,
                            },
                        },
                    },
                    "&.Mui-active": {
                        color: "inherit",
                        "&:hover": {
                            color: baseTheme.palette.primary.main,
                            ".MuiTableSortLabel-icon": {
                                color: baseTheme.palette.primary.main,
                            },
                        },
                        ".MuiTableSortLabel-icon": {
                            visibility: "visible",
                            color: baseTheme.palette.midGray,
                        },
                    },
                    ".MuiTableSortLabel-icon": {
                        visibility: "hidden",
                    },
                },
            },
            defaultProps: {
                IconComponent: TableSortChevron,
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    ".MuiTab-root:first-of-type": {
                        marginLeft: 0,
                    },
                    ".MuiTab-root:last-of-type": {
                        marginRight: 0,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: "Lexend",
                    fontSize: "1rem",
                    textTransform: "inherit",
                    padding: `${baseTheme.spacing(3)} 0px`,
                    margin: `0px ${baseTheme.spacing(2)}`,
                    minWidth: "unset",
                    borderWidth: 1,
                },
                textColorPrimary: {
                    color: baseTheme.palette.midGray,
                    "&.Mui-selected": {
                        color: baseTheme.palette.primary.main,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                    },
                    "input[type=number]": {
                        MozAppearance: "textfield",
                    },
                    [`${inputAutoFillClasses}`]: {
                        transition: "background-color 5000s ease-in-out 0s",
                        WebkitTextFillColor: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    [baseTheme.breakpoints.down("sm")]: {
                        paddingLeft: baseTheme.spacing(3),
                        paddingRight: baseTheme.spacing(3),
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: baseTheme.palette.background.paper,
                    border: `1px solid ${baseTheme.palette.divider}`,
                    borderRadius: baseTheme.spacing(2),
                    fontFamily: "Lexend",
                    fontWeight: 300,
                    fontSize: "0.75rem",
                    padding: baseTheme.spacing(2),
                },
            },
        },
    },
});
