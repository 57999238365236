import { captureException } from "@sentry/nextjs";
import axios, {
    AxiosProgressEvent,
    AxiosRequestConfig,
    AxiosResponseHeaders,
    Method,
    RawAxiosResponseHeaders,
} from "axios";

import { config } from "../config";
import { getClientSessionToken, setClientSessionToken } from "./cookies";
import { createUrlQueryParamString } from "./url";

export enum APICode {
    NoResponse = -1,
    Success = 0,
    Error = 1,
    TokenExpired = 2,
    NFTRequirementsNotMet = 5,
    AlreadyClaimedTickets = 6,
    TooManyVerificationRequests = 8,
    RefreshTooSoon = 9,
}

export class APIError extends Error {
    constructor(
        public code: APICode,
        public message: string,
        public status?: number,
    ) {
        super(message);

        this.code = code;
        this.status = status;
    }
}

interface ApiGetOptions extends ClientApiGetOptions {
    sessionToken?: string;
}

export function apiGet<T>(route: string, { args, sessionToken, shouldAlert }: ApiGetOptions = {}) {
    const requestRoute = args
        ? `${route}${route.indexOf("?") === -1 ? "?" : "&"}${createUrlQueryParamString(args)}`
        : route;

    return apiRequest<T>("GET", requestRoute, undefined, sessionToken, shouldAlert);
}

interface ApiPostOptions extends ClientApiPostOptions {
    sessionToken?: string;
}

export function apiPost<T>(route: string, { body = {}, sessionToken, shouldAlert }: ApiPostOptions = {}) {
    return apiRequest<T>("POST", route, body, sessionToken, shouldAlert);
}

type ApiPatchOptions = ApiPostOptions;

export function apiPatch<T>(route: string, { body = {}, sessionToken, shouldAlert }: ApiPatchOptions = {}) {
    return apiRequest<T>("PATCH", route, body, sessionToken, shouldAlert);
}

export interface ClientApiGetOptions {
    args?: Record<string, string | number | boolean | string[] | number[]>;
    customQueryParamString?: string;
    shouldAlert?: (status?: number, error?: any) => boolean;
}

export function clientApiGet<T>(
    route: string,
    { args, customQueryParamString, shouldAlert }: ClientApiGetOptions = {},
) {
    const requestRoute = customQueryParamString
        ? `${route}?${customQueryParamString}`
        : args
          ? `${route}${route.indexOf("?") === -1 ? "?" : "&"}${createUrlQueryParamString(args)}`
          : route;

    return clientApiRequest<T>("GET", requestRoute, undefined, shouldAlert);
}

interface ClientApiPostOptions {
    body?: object;
    shouldAlert?: (status?: number, error?: any) => boolean;
}

export function clientApiPost<T>(route: string, { body = {}, shouldAlert }: ClientApiPostOptions = {}) {
    return clientApiRequest<T>("POST", route, body, shouldAlert);
}

type ClientApiPatchOptions = ClientApiPostOptions;

export function clientApiPatch<T>(route: string, { body = {}, shouldAlert }: ClientApiPatchOptions = {}) {
    return clientApiRequest<T>("PATCH", route, body, shouldAlert);
}

type ClientApiDeleteOptions = ClientApiPostOptions;

export function clientApiDelete<T>(route: string, { body = {}, shouldAlert }: ClientApiDeleteOptions = {}) {
    return clientApiRequest<T>("DELETE", route, body, shouldAlert);
}

export const apiPutFile = (url: string, file: File, onUploadProgress?: (progress: number) => void) =>
    axios.request({
        method: "PUT",
        data: file,
        headers: { "Content-Type": file.type },
        url,
        onUploadProgress: onUploadProgress
            ? ({ loaded, total }: AxiosProgressEvent) =>
                  total !== undefined ? onUploadProgress((loaded / total) * 100) : undefined
            : undefined,
    });

interface APIResponse<T> {
    code: APICode;
    message: string;
    response: T;
}

interface WebResponse<T> {
    data: T;
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
}

async function http<T>(
    request: AxiosRequestConfig,
    shouldAlert: (status: number | undefined, error: any) => boolean = () => true,
): Promise<WebResponse<T>> {
    const response = await axios.request<APIResponse<T>>(request).catch((error) => {
        let errorMessage = error.response?.data?.message ?? "Unknown error";
        const status = error.response?.status;
        const willAlert = status !== 404 && shouldAlert(status, error);

        if (error.response?.data?.code !== undefined) {
            if (error.response?.data?.code !== APICode.TokenExpired && willAlert) {
                captureAxiosException(error.response?.data?.message, status, error);
            }

            throw new APIError(error.response.data.code, errorMessage, status);
        }

        if (willAlert) {
            if (!!error.response) {
                captureAxiosException(error.response?.data?.message, status, error);
            } else {
                captureException(error);

                // error.request is defined if no response was received
                if (!!error.request) {
                    errorMessage = "no response received";
                }
            }
        }

        throw new APIError(!error.request ? APICode.Error : APICode.NoResponse, errorMessage, status);
    });

    if (response.data.code === APICode.Success) {
        return { data: response.data.response, headers: response.headers };
    }

    const error = new APIError(response.data.code, response.data.message, response.status);
    if (response.status !== 404 && shouldAlert(response.status, error)) {
        captureAxiosException(error.message, error.status, error);
    }

    throw new APIError(response.data.code, response.data.message, response.status);
}

const captureAxiosException = (message: any, status: any, error: any) =>
    captureException(createErrorMessage(message, status, error));

const createErrorMessage = (message: any, status: any, error: any) => {
    if (message !== undefined && typeof message === "string") {
        return status !== undefined
            ? `Request failed with status code: ${status}. Message: ${message}`
            : `Request failed with message: ${message}`;
    }

    return error;
};

function apiRequest<T>(
    method: Method,
    route: string,
    body?: object,
    sessionToken?: string,
    shouldAlert?: (status?: number, error?: any) => boolean,
) {
    return http<T>(
        {
            method,
            url: `${config.apiHost}${route}`,
            ...(body ? { data: JSON.stringify(body) } : {}),
            headers: getHeaders(sessionToken),
            timeout: 30_000,
        },
        shouldAlert,
    ).then((response) => response.data);
}

async function clientApiRequest<T>(
    method: Method,
    route: string,
    body: object | undefined,
    shouldAlert?: (status?: number, error?: any) => boolean,
) {
    const sessionToken = getClientSessionToken();

    try {
        const response = await http<T>(
            {
                method,
                url: `${config.apiHost}${route}`,
                ...(body ? { data: JSON.stringify(body) } : {}),
                headers: getHeaders(sessionToken),
            },
            shouldAlert,
        );

        const newSessionToken = response.headers["Set-Chainpass-Session-Token"];
        if (newSessionToken) {
            setClientSessionToken(newSessionToken);
        }
        return response.data;
    } catch (error) {
        if (error instanceof APIError && error.code === APICode.TokenExpired) {
            return;
        }
        throw error;
    }
}

const getHeaders = (sessionToken?: string) => ({
    "Content-Type": "application/json",
    ...(sessionToken ? { "Chainpass-Session-Token": sessionToken } : {}),
});
