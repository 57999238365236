import Cookies from "js-cookie";
import type { GetServerSidePropsContext } from "next/types";

import { GitbookTokenInfo } from "../models/gitbookTokenInfo";
import { parseInteger } from "./parse";

const SESSION_TOKEN_KEY = "Chainpass-Session-Token";
const SELECTED_ORGANIZATION_KEY = "Selected-Organization-Id";
const PUBLIC_TOKEN_KEY = "Chainpass-Public-Token";
const GITBOOK_TOKEN_KEY = "Chainpass-Gitbook-Token";

export const setClientSessionToken = (sessionToken: string) =>
    Cookies.set(SESSION_TOKEN_KEY, sessionToken, { expires: 30 });

export const setClientPublicToken = (publicToken: string) => Cookies.set(PUBLIC_TOKEN_KEY, publicToken, { expires: 1 });

export const setClientOrganizationId = (organizationId: number) => {
    Cookies.set(SELECTED_ORGANIZATION_KEY, organizationId.toString(), { expires: 365 });
};

export const setClientGitbookTokenInfo = (gitbookTokenInfo: GitbookTokenInfo) =>
    Cookies.set(GITBOOK_TOKEN_KEY, JSON.stringify(gitbookTokenInfo), { expires: new Date(gitbookTokenInfo.expiry) });

export const getClientSessionToken = () => Cookies.get(SESSION_TOKEN_KEY);

export const getClientPublicToken = () => Cookies.get(PUBLIC_TOKEN_KEY);

export const getClientOrganizationId = () => parseInteger(Cookies.get(SELECTED_ORGANIZATION_KEY));

export const getClientGitbookTokenInfo = () => {
    const value = Cookies.get(GITBOOK_TOKEN_KEY);
    if (value) {
        try {
            return JSON.parse(value) as GitbookTokenInfo;
        } catch {}
    }
};

export const getSessionTokenFromContext = (context: GetServerSidePropsContext) =>
    context.req.cookies[SESSION_TOKEN_KEY];

export const getOrganizationIdFromContext = (context: GetServerSidePropsContext) =>
    parseInteger(context.req.cookies[SELECTED_ORGANIZATION_KEY]);

export const clearClientSessionToken = () => Cookies.remove(SESSION_TOKEN_KEY);

export const clearClientPublicToken = () => Cookies.remove(PUBLIC_TOKEN_KEY);

export const clearClientGitbookTokenInfo = () => Cookies.remove(GITBOOK_TOKEN_KEY);
