import { APICode, APIError } from "./api";

export const handleErrorToast = ({
    error,
    message,
    errorToast,
}: {
    error?: any;
    message: string;
    errorToast: (message: string) => void;
}) => {
    if (!!error && error instanceof APIError && error.code === APICode.NoResponse) {
        errorToast("No response received. Check your internet connection.");
    } else {
        errorToast(message);
    }
};
