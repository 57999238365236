import clsx from "clsx";
import { useRouter } from "next/router";
import React from "react";

import { config } from "../config";
import { BasePageContext } from "../state/basePageContext";
import { makeStyles } from "../styles/makeStyles";
import { clearClientPublicToken } from "../util/cookies";
import { getImageUrl } from "../util/img";
import { createUrl } from "../util/url";

const useStyles = makeStyles<{ smallBreakpoint: number | undefined }>()((_, { smallBreakpoint }) => ({
    logo: {
        display: "block",
        width: "fit-content",
        lineHeight: 0,
        ...(smallBreakpoint !== undefined
            ? {
                  "&.logo-type": {
                      [`@media (max-width: ${smallBreakpoint}px)`]: {
                          display: "none",
                      },
                  },
                  "&.logo-mark": {
                      [`@media (min-width: ${smallBreakpoint + 1}px)`]: {
                          display: "none",
                      },
                  },
              }
            : {}),
    },
}));

interface Props {
    className?: string;
    smallBreakpoint?: number;
    deferActionCallback?: (deferCallback: () => void) => void;
}

export const CymbalLogoAnchor: React.FC<Props> = React.memo(({ className, smallBreakpoint, deferActionCallback }) => {
    const { classes } = useStyles({ smallBreakpoint });
    const { publicToken } = React.useContext(BasePageContext);
    const router = useRouter();

    const url = createUrl(config.webHost, publicToken ? { p: publicToken } : undefined);

    const onClick = deferActionCallback
        ? (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              deferActionCallback(() => {
                  clearClientPublicToken();
                  router.push(url);
              });
          }
        : clearClientPublicToken;

    return (
        <>
            <a className={clsx(className, classes.logo, "logo-type")} href={url} onClick={onClick}>
                <img
                    src={getImageUrl("/assets/cymbal-logotype-light.webp", { height: 120 })}
                    height={24}
                    alt="Cymbal logotype"
                />
            </a>
            {smallBreakpoint !== undefined && (
                <a className={clsx(className, classes.logo, "logo-mark")} href={url} onClick={onClick}>
                    <img
                        src={getImageUrl("/assets/cymbal-logo-light.webp", { height: 120 })}
                        height={23.4}
                        alt="Cymbal logo"
                    />
                </a>
            )}
        </>
    );
});
