import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

import { IconProps } from "../util/icon";

export const DownChevronIcon: React.FC<IconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 16 16">
        <path d="M12.78 5.22a.749.749 0 0 1 0 1.06l-4.25 4.25a.749.749 0 0 1-1.06 0L3.22 6.28a.749.749 0 1 1 1.06-1.06L8 8.939l3.72-3.719a.749.749 0 0 1 1.06 0Z" />
    </SvgIcon>
);
