import clsx from "clsx";
import React from "react";

import { ResizableInput } from "../../../../shared/components/genreSearchInput/resizableInput";
import { makeStyles } from "../../../../shared/styles/makeStyles";

const useStyles = makeStyles()((theme) => ({
    inputWrapper: {
        margin: `0 ${theme.spacing(-0.5)}`,
    },
    input: {
        "&.cymbal-color-picker-input": {
            borderRadius: theme.spacing(0.5),
            padding: `0 ${theme.spacing(0.5)}`,

            "&:focus": {
                backgroundColor: theme.palette.background.highlight,
            },
        },
    },
}));

interface BaseProps {
    value: string | number | undefined;
    inputRef?: React.RefObject<HTMLInputElement>;
}

type Props = BaseProps & React.HTMLAttributes<HTMLInputElement>;

export const ColorPickerInput: React.FC<Props> = React.memo(({ className, inputRef, value, ...rest }) => {
    const { classes } = useStyles();

    return (
        <ResizableInput
            inputRef={inputRef}
            className={clsx(className, classes.inputWrapper)}
            inputClassName={clsx("cymbal-color-picker-input", classes.input)}
            value={value}
            minWidth={0}
            {...rest}
        />
    );
});
