import CrawlerUserAgents from "crawler-user-agents";
import { detect } from "detect-browser";
import type { GetServerSidePropsContext, GetServerSidePropsResult } from "next/types";

import { Organization } from "../models/organization";
import { OrganizationsSessionInfo, SessionInfo } from "../models/sessionInfo";
import { User } from "../models/user";
import { UserSessionInfo } from "../models/userSessionInfo";
import { APICode, APIError, apiGet, apiPost } from "./api";
import { getOrganizationIdFromContext, getSessionTokenFromContext } from "./cookies";
import { parseInteger, parseNumber } from "./parse";
import { createUrl } from "./url";

export const ssRedirect = (route: string, params: { [param: string]: string | number | boolean } = {}) => ({
    redirect: { destination: createUrl(route, params), permanent: false },
});

export const getQueryParameterFromContext = (context: GetServerSidePropsContext, param: string): string | undefined => {
    const rawParam = context.query[param];
    return Array.isArray(rawParam) ? rawParam[0] : rawParam;
};

export const getBooleanQueryParamFromContext = (
    context: GetServerSidePropsContext,
    param: string,
): boolean | undefined => {
    const rawParam = getQueryParameterFromContext(context, param);
    return rawParam !== undefined ? rawParam === "true" : undefined;
};

export const getNumberQueryParamFromContext = (context: GetServerSidePropsContext, param: string): number | undefined =>
    parseNumber(getQueryParameterFromContext(context, param));

export const getUrlParameterFromContext = (context: GetServerSidePropsContext, param: string) => {
    const rawParam = context.params ? context.params[param] : undefined;

    return rawParam && typeof rawParam === "string" ? rawParam : undefined;
};

export const getNumberUrlParamFromContext = (context: GetServerSidePropsContext, param: string): number | undefined =>
    parseInteger(getUrlParameterFromContext(context, param));

export const getOrganizationsSessionInfoFromContextNoPublicToken = async (
    context: GetServerSidePropsContext,
): Promise<OrganizationsSessionInfo | undefined> => {
    const sessionToken = getSessionTokenFromContext(context);
    if (!sessionToken) {
        return;
    }

    const user = await getUserFromSessionToken(sessionToken);
    if (!user) {
        return;
    }

    const organizationIdCookie = getOrganizationIdFromContext(context);

    try {
        const organizations = await apiGet<Organization[]>("/orgs", { sessionToken });
        if (organizations.length === 0) {
            return;
        }

        const selectedOrg =
            organizationIdCookie !== undefined
                ? organizations.find((org) => org.id === organizationIdCookie)
                : undefined;

        return { user, sessionToken, organizations, selectedOrg };
    } catch {}
};

export const getSessionInfoFromContext = async (
    context: GetServerSidePropsContext,
    sessionTokenQueryParam?: string,
): Promise<SessionInfo | undefined> => {
    const publicToken = getPublicTokenFromContext(context);

    if (publicToken) {
        const userSessionInfo = await getUserSessionInfoFromPublicToken(publicToken);
        if (userSessionInfo) {
            const { selectedOrg, organizations } = await getOrganizationInfo(context, userSessionInfo.sessionToken);

            if (selectedOrg) {
                return {
                    ...userSessionInfo,
                    organization: selectedOrg,
                    organizations,
                    usedPublicToken: true,
                };
            }
        }
    }

    const sessionToken = sessionTokenQueryParam ?? getSessionTokenFromContext(context);
    if (!sessionToken) {
        return;
    }

    try {
        const organization = await getOrganization(context, sessionToken);
        if (!organization) {
            return;
        }

        return {
            sessionToken,
            organization,
        };
    } catch (error) {
        if (error instanceof APIError && error.code === APICode.TokenExpired) {
            return;
        }

        throw error;
    }
};

export const getUserSessionInfoFromContext = async (
    context: GetServerSidePropsContext,
): Promise<UserSessionInfo | undefined> => {
    const publicToken = getPublicTokenFromContext(context);
    if (publicToken) {
        const userSessionInfo = await getUserSessionInfoFromPublicToken(publicToken);
        if (userSessionInfo) {
            return userSessionInfo;
        }
    }

    const sessionToken = getSessionTokenFromContext(context);
    if (!sessionToken) {
        return;
    }

    const user = await getUserFromSessionToken(sessionToken);
    if (!user) {
        return;
    }

    return {
        sessionToken,
        user,
    };
};

const getUserFromSessionToken = async (sessionToken: string | undefined) => {
    if (sessionToken) {
        try {
            return await apiGet<User>("/user", { sessionToken });
        } catch {}
    }
};

const getPublicTokenFromContext = (context: GetServerSidePropsContext) => {
    const publicToken = context.query["p"];

    if (Array.isArray(publicToken)) {
        return publicToken.length !== 0 ? publicToken[0] : undefined;
    }

    return publicToken;
};

const getUserSessionInfoFromPublicToken = async (
    publicToken: string | undefined,
): Promise<UserSessionInfo | undefined> => {
    if (publicToken) {
        try {
            const { user, session_token } = await apiPost<{ user: User; session_token: string }>(
                "/public_token/decrypt",
                {
                    body: {
                        public_token: publicToken,
                    },
                },
            );

            return {
                user,
                sessionToken: session_token,
            };
        } catch {}
    }
};

export const getOrganization = async (
    context: GetServerSidePropsContext,
    sessionToken: string,
): Promise<Organization | undefined> => {
    const orgInfo = await getOrganizationInfo(context, sessionToken);

    return orgInfo.selectedOrg ?? (orgInfo.organizations.length !== 0 ? orgInfo.organizations[0] : undefined);
};

export const getOrganizationInfo = async (
    context: GetServerSidePropsContext,
    sessionToken: string,
): Promise<{
    selectedOrg?: Organization;
    organizations: Organization[];
}> => {
    const jumpToOrgID = getNumberQueryParamFromContext(context, "jump_to_org_id");

    let jumpToOrg: Organization | undefined;
    if (jumpToOrgID !== undefined) {
        jumpToOrg = await getJumpToOrganization(jumpToOrgID, sessionToken);
    }

    const organizationIdCookie = getOrganizationIdFromContext(context);
    const organizations = await getUserOrganizations(sessionToken, organizationIdCookie);
    if (jumpToOrg !== undefined) {
        return { selectedOrg: jumpToOrg, organizations: organizations };
    }

    if (organizations.length !== 0) {
        let org: Organization | undefined;
        if (organizationIdCookie !== undefined) {
            const filteredOrgs = organizations.filter((org) => org.id === organizationIdCookie);
            org = filteredOrgs.length !== 0 ? filteredOrgs[0] : undefined;
        }

        return {
            selectedOrg: org ?? organizations[0],
            organizations,
        };
    }

    return { organizations: [] };
};

const getUserOrganizations = async (sessionToken: string, superAdminIncludeId?: number) => {
    try {
        return apiGet<Organization[]>("/orgs", {
            sessionToken,
            args:
                superAdminIncludeId !== undefined
                    ? {
                          super_admin_include_id: superAdminIncludeId,
                      }
                    : undefined,
        });
    } catch {
        return [];
    }
};

const getJumpToOrganization = async (orgId: number, sessionToken: string) => {
    try {
        return await apiGet<Organization>(`/org/${orgId}`, {
            sessionToken,
            args: {
                enforce_admin: true,
            },
            shouldAlert: (status) => status !== 401,
        });
    } catch {}
};

export interface ServerErrorResponse {
    serverSideRequestError: {
        error: any;
        notFound: boolean;
        tokenExpired: boolean;
        unauthorized: boolean;
    };
}

export type ServerSideApiRequestResponse<T> = T | ServerErrorResponse;

export function isServerErrorResponse<T>(response: ServerSideApiRequestResponse<T>): response is ServerErrorResponse {
    return (response as ServerErrorResponse)?.serverSideRequestError !== undefined;
}

export async function callServerSideFunction<T>(fn: () => Promise<T>): Promise<ServerSideApiRequestResponse<T>> {
    try {
        return await fn();
    } catch (error: any) {
        return {
            serverSideRequestError: {
                error,
                notFound: error?.status === 404,
                tokenExpired: error instanceof APIError && error.code === APICode.TokenExpired,
                unauthorized: error?.status === 401,
            },
        };
    }
}

export function handleServerSideErrorResponse<T>(
    { serverSideRequestError }: ServerErrorResponse,
    {
        loginDestination,
        unauthorizedAsNotFound = true,
    }: { loginDestination?: string; unauthorizedAsNotFound?: boolean } = {},
): GetServerSidePropsResult<T> {
    const { notFound, tokenExpired, unauthorized, error } = serverSideRequestError;

    if (tokenExpired || (unauthorized && !unauthorizedAsNotFound)) {
        return ssRedirect("/login", loginDestination ? { d: loginDestination } : {});
    } else if (notFound || (unauthorized && unauthorizedAsNotFound)) {
        return { notFound: true };
    }

    throw error;
}

const crawlerUserAgentPatterns = CrawlerUserAgents.reduce(
    (patterns, userAgent) => [...patterns, userAgent.pattern],
    [] as string[],
);

export const isCrawlerUserAgent = (context: GetServerSidePropsContext) => {
    const browser = detect(context.req.headers["user-agent"]);

    return (
        !browser ||
        browser.type !== "browser" ||
        !!crawlerUserAgentPatterns.find((pattern) => RegExp(pattern).test(context.req.headers["user-agent"] || ""))
    );
};
