import { Organization } from "../models/organization";
import { RectangleSize } from "../models/rectangle";
import { TicketerIntegrationType } from "../models/sourceIntegration";
import { createUrl } from "./url";

export const fastlyImageHost = "https://files.cymbal.co";

export const getImageUrl = (path: string, params: { format?: ImageFormat; width?: number; height?: number } = {}) =>
    createUrl(`${fastlyImageHost}${path}`, params);

export const getOrganizationImage = (organization: Organization) => {
    if (organization.cover_photo) {
        return addPropertiesIfFastlyUrl(organization.cover_photo, {
            width: 320,
            height: 320,
            fit: "cover",
            format: "webp",
        });
    }

    const orgLetters = organization.name.replace(/[^a-zA-Z]/gi, "");
    const character = orgLetters.length !== 0 ? orgLetters.charAt(0).toLocaleLowerCase() : "c";

    return getImageUrl(`/avatars/${character}.png`, { width: 320 });
};

export const getIntegrationImagePath = (type: TicketerIntegrationType) => {
    switch (type) {
        case "eventbrite":
            return "/assets/manager/eventbrite.webp";
        case "etix":
            return "/assets/manager/etix.webp";
        case "dice":
            return "/assets/manager/dice.webp";
        case "prekindle":
            return "/assets/manager/prekindle.webp";
        case "posh":
            return "/assets/manager/posh.webp";
        case "ticketsauce":
            return "/assets/manager/ticketsauce.webp";
        case "ticketweb":
            return "/assets/manager/ticketweb.png?format=webp";
        case "tixr":
            return "/assets/manager/tixr.webp";
        case "venue_pilot":
            return "/assets/manager/venue-pilot.webp";
        case "showclix":
            return "/assets/manager/showclix.webp";
        case "freshtix":
            return "/assets/manager/freshtix.webp";
        case "holdmyticket":
            return "/assets/manager/hold-my-ticket.webp";
    }
};

type ImageFormat = "jpeg" | "png" | "webp";

interface FastlyProperties {
    width?: number;
    height?: number;
    format?: ImageFormat;
    fit?: "bounds" | "cover";
    crop?: string;
}

export const addPropertiesIfFastlyUrl = (url: string, properties: FastlyProperties) => {
    const split = url.split("?");

    if (url.startsWith(fastlyImageHost)) {
        if (split.length === 1) {
            const urlParamsList = [
                ...(properties.format !== undefined ? [queryParamEntry("format", properties.format)] : []),
                ...(properties.width !== undefined ? [queryParamEntry("width", properties.width)] : []),
                ...(properties.height !== undefined ? [queryParamEntry("height", properties.height)] : []),
                ...(properties.fit !== undefined ? [queryParamEntry("fit", properties.fit)] : []),
                ...(properties.crop !== undefined ? [queryParamEntry("crop", properties.crop)] : []),
            ];

            return `${split[0]}?${urlParamsList.join("&")}`;
        }

        let hasWidth = false;
        let hasHeight = false;
        let hasFit = false;
        let hasFormat = false;
        let hasCrop = false;

        const urlParams = new URLSearchParams(split[1]);
        const urlParamsList = Array.from(urlParams.entries())
            .map(([key, value]) => {
                switch (key.toLocaleLowerCase()) {
                    case "width":
                        hasWidth = true;
                        break;
                    case "height":
                        hasHeight = true;
                        break;
                    case "fit":
                        hasFit = true;
                        if (properties.fit !== undefined) {
                            return queryParamEntry("fit", properties.fit);
                        }
                        break;
                    case "format":
                        hasFormat = true;
                        if (properties.format !== undefined) {
                            return queryParamEntry("format", properties.format);
                        }
                        break;
                    case "crop":
                        hasCrop = true;
                        if (properties.crop !== undefined) {
                            return queryParamEntry("crop", properties.crop);
                        }
                        break;
                    default:
                        break;
                }

                return queryParamEntry(key, value);
            })
            .filter((value) => value !== "");

        if (!hasWidth && properties.width !== undefined) {
            urlParamsList.push(queryParamEntry("width", properties.width));
        }

        if (!hasHeight && properties.height !== undefined) {
            urlParamsList.push(queryParamEntry("height", properties.height));
        }

        if (!hasFit && properties.fit !== undefined) {
            urlParamsList.push(queryParamEntry("fit", properties.fit));
        }

        if (!hasFormat && properties.format !== undefined) {
            urlParamsList.push(queryParamEntry("format", properties.format));
        }

        if (!hasCrop && properties.crop !== undefined) {
            urlParamsList.push(queryParamEntry("crop", properties.crop));
        }

        return `${split[0]}?${urlParamsList.join("&")}`;
    }

    return url;
};

const queryParamEntry = (key: string, value: number | string) => `${key}=${value}`;

export const getImageDimensions = (url: string): Promise<RectangleSize> => {
    return new Promise((resolve, reject) => {
        if (typeof window !== "undefined") {
            const img = new Image();

            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };

            img.onerror = (error) => {
                reject(`Error loading image: ${error}`);
            };

            img.src = url;
        }
    });
};
