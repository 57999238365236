import { allSupportedFonts, supportedMonospaceFonts, supportedSerifFonts } from "@cymbal/legos";
import { format } from "date-fns";
import Cookies from "js-cookie";
import { v4 } from "uuid";

import { LegoTab } from "../components/frames/legoFrame/legoFrame";
import { Event } from "../models/event";
import type {
    AutomationTriggeredEventLegoComponent,
    AutomationUpcomingEventsLegoComponent,
    ColumnLegoComponent,
    EventLegoComponent,
    FormLegoComponent,
    HorizontalEventLegoComponent,
    ImageLeafLegoComponent,
    LeafLegoComponent,
    LegoComponent,
    LegoComponentGroup,
    LegoComponentGroupRow,
    LegoComponentWithLeaf,
    LegoEventArrangement,
    LegoFormInputSubmission,
    LegoFormQuestionSubmission,
    LegoTemplate,
    QuestionLegoComponent,
    SingleLegoEventArrangement,
    TextAddLegoComponentType,
    TextLeafLegoComponentType,
} from "../models/legos";
import { MergedLegoTemplateStyles, NonNullableLegoEventStyle } from "../models/mergedLegos";
import {
    PostLegoComponentBody,
    PostLegoComponentBodyWithAutomationTriggeredEvent,
    PostLegoComponentBodyWithAutomationUpcomingEvents,
    PostLegoComponentBodyWithColumn,
    PostLegoComponentBodyWithEvent,
    PostLegoComponentBodyWithForm,
    PostLegoComponentBodyWithHorizontalEvent,
    PostLegoComponentBodyWithLeaf,
    PostLegoComponentBodyWithQuestion,
} from "../models/postLegos";
import { substitutePreviewTextForDynamicDataTypes } from "./contentEditable";
import { adjustForTimezone } from "./date";
import { getImageUrl } from "./img";
import { sortByEventOrdinals } from "./mergedLegos";
import {
    getInvalidComponentIdLegoTemplate,
    getInvalidComponentIdLegoTemplateFooter,
    getInvalidComponentIdLegoTemplateHeader,
} from "./validateLegos";

export const convertToTextLeafLegoComponentType = (type: TextAddLegoComponentType): TextLeafLegoComponentType => {
    switch (type) {
        case "heading-text":
            return "heading";
        case "subheading-text":
            return "subheading";
        case "title-text":
            return "title";
        case "subtitle-text":
            return "subtitle";
        case "section-text":
            return "section";
        case "body-text":
            return "body";
        case "accent-text":
            return "accent";
        case "caption-text":
            return "caption";
    }
};

export const convertToTextAddLegoComponentType = (type: TextLeafLegoComponentType): TextAddLegoComponentType => {
    switch (type) {
        case "heading":
            return "heading-text";
        case "subheading":
            return "subheading-text";
        case "title":
            return "title-text";
        case "subtitle":
            return "subtitle-text";
        case "section":
            return "section-text";
        case "body":
            return "body-text";
        case "accent":
            return "accent-text";
        case "caption":
            return "caption-text";
    }
};

export const legoFontFamilyDropdownOptions = [...allSupportedFonts]
    .sort((a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    })
    .map((fontFamily) => ({
        key: fontFamily,
        value: fontFamily,
        label: fontFamily,
    }));

const LEGOS_STYLE_CUSTOM_COLORS_KEY = "Legos-Style-Custom-Colors";

export const setCookieLegosStyleCustomColors = (colors: string[]) =>
    Cookies.set(LEGOS_STYLE_CUSTOM_COLORS_KEY, JSON.stringify(colors));

export const getCookieLegosStyleCustomColors = () => {
    const cookieValue = Cookies.get(LEGOS_STYLE_CUSTOM_COLORS_KEY);
    return cookieValue ? (JSON.parse(cookieValue) as string[]) : [];
};

export const LEGO_BUILDER_FULLSCREEN_BREAKPOINT = 1100;

export const getFontFamily = (fontFamily: (typeof allSupportedFonts)[number]) => {
    if (supportedMonospaceFonts.includes(fontFamily as any)) {
        return `"${fontFamily}", monospace`;
    } else if (supportedSerifFonts.includes(fontFamily as any)) {
        return `"${fontFamily}", serif`;
    } else {
        return `"${fontFamily}", sans-serif`;
    }
};

export const getLongFormatLegoDateString = (event: Event) =>
    event.start_datetime
        ? format(
              !event.location
                  ? new Date(event.start_datetime)
                  : adjustForTimezone(new Date(event.start_datetime), event.location.timezone, true),
              "EEEE, LLLL d, h:mmaaa",
          )
        : "";

const textTypePriority = {
    title: 1,
    subtitle: 2,
    heading: 3,
    subheading: 4,
    section: 5,
    body: 6,
    accent: 7,
    caption: 8,
} as const;

const sortTextComponentsByPriority = (a: LegoComponentGroupRow, b: LegoComponentGroupRow) => {
    if (
        a.component.type === "leaf" &&
        a.component.leaf_component.type === "text" &&
        b.component.type === "leaf" &&
        b.component.leaf_component.type === "text"
    ) {
        return (
            textTypePriority[a.component.leaf_component.text_component.text_type] -
            textTypePriority[b.component.leaf_component.text_component.text_type]
        );
    }

    return 0;
};

const cleanPreviewText = (text: string) =>
    substitutePreviewTextForDynamicDataTypes(
        text.replace(/<\/?span[^>]*>/gi, "").replace(/\[([^\]]+)\]\(([^)]+)\)/gi, "$1"),
    );

export const getEventTextPreview = (
    eventLegoComponent: EventLegoComponent,
    event: Event,
    mergedTemplateStyles: MergedLegoTemplateStyles,
) => {
    const eventRows = eventLegoComponent.component_group.rows;

    const previewTextRow =
        eventRows.filter(
            (row) =>
                row.component.type === "leaf" &&
                row.component.leaf_component.type === "text" &&
                row.component.leaf_component.text_component.text_type ===
                    mergedTemplateStyles.event_style.name_text_type,
        )[0] ||
        eventRows
            .filter((row) => row.component.type === "leaf" && row.component.leaf_component.type === "text")
            .sort(sortTextComponentsByPriority)[0];

    return previewTextRow &&
        previewTextRow.component.type === "leaf" &&
        previewTextRow.component.leaf_component.type === "text"
        ? cleanPreviewText(previewTextRow.component.leaf_component.text_component.text)
        : event.name;
};

export const getEventImagePreview = (eventLegoComponent: EventLegoComponent, event: Event) => {
    const eventRows = eventLegoComponent.component_group.rows;

    const previewImageRow = eventRows.filter(
        (row) => row.component.type === "leaf" && row.component.leaf_component.type === "image",
    )[0];

    return previewImageRow &&
        previewImageRow.component.type === "leaf" &&
        previewImageRow.component.leaf_component.type === "image"
        ? previewImageRow.component.leaf_component.image_component.src
        : event?.cover_photo;
};

export const getBlockImagePreview = (group: LegoComponentGroup) => {
    const previewImageRow = group.rows.filter(
        (row) =>
            row.component.type === "leaf" &&
            row.component.leaf_component.type === "image" &&
            row.component.leaf_component.image_component.src !== emptyLegoImageSmallUrl,
    )[0];

    return previewImageRow &&
        previewImageRow.component.type === "leaf" &&
        previewImageRow.component.leaf_component.type === "image"
        ? previewImageRow.component.leaf_component.image_component.src
        : "";
};

export const getBlockTextPreview = (group: LegoComponentGroup) => {
    const { rows } = group;

    const previewTextRow = rows
        .filter(
            (row) =>
                row.component.type === "leaf" &&
                row.component.leaf_component.type === "text" &&
                row.component.leaf_component.text_component.text !== "",
        )
        .sort(sortTextComponentsByPriority)[0];

    if (!previewTextRow) {
        const buttonRow = rows.filter(
            (row) => row.component.type === "leaf" && row.component.leaf_component.type === "button",
        )[0];

        return buttonRow && buttonRow.component.type === "leaf" && buttonRow.component.leaf_component.type === "button"
            ? buttonRow.component.leaf_component.button_component.text
            : "";
    }

    return previewTextRow.component.type === "leaf" && previewTextRow.component.leaf_component.type === "text"
        ? cleanPreviewText(previewTextRow.component.leaf_component.text_component.text)
        : "";
};

export const getInitialBlockComponents = (): PostLegoComponentBodyWithLeaf[] => {
    return [
        {
            type: "leaf",
            leaf: {
                type: "image",
                image: {
                    src: emptyLegoImageSmallUrl,
                    href: "",
                },
            },
        },
        {
            type: "leaf",
            leaf: {
                type: "text",
                text: {
                    text_type: "subheading",
                    text: "",
                },
            },
        },
        {
            type: "leaf",
            leaf: {
                type: "text",
                text: {
                    text_type: "body",
                    text: "",
                },
            },
        },
        {
            type: "leaf",
            leaf: {
                type: "button",
                button: {
                    text: "Button text",
                    href: "",
                },
            },
        },
    ];
};

const leafComponentIsEmptyOrDefault = (leafComponent: LeafLegoComponent) => {
    switch (leafComponent.type) {
        case "text":
            return leafComponent.text_component.text === "";
        case "button":
            return leafComponent.button_component.text === "" || leafComponent.button_component.text === "Button text";
        case "promo-code":
            return leafComponent.promo_code_component.text === "";
        case "image":
            return leafComponent.image_component.src === emptyLegoImageSmallUrl;
        case "option":
            return leafComponent.option_component.text === "";
        case "divider":
        case "spacer":
        case "input":
            return false;
    }
};

export const hasAddedBlockContents = (group: LegoComponentGroup) => {
    const { rows } = group;

    if (rows.length === 0) {
        return false;
    }

    return rows.some((row) => {
        if (row.component.type === "leaf") {
            return !leafComponentIsEmptyOrDefault(row.component.leaf_component);
        }
        return false;
    });
};

export const getHorizontalEventImagePreview = (horizontalEventLegoComponent: HorizontalEventLegoComponent) => {
    return horizontalEventLegoComponent.image_leaf_lego_component.src;
};

export const convertToLegoTab = (param: string | undefined): LegoTab | undefined => {
    switch (param) {
        case "main":
        case "style":
        case "analytics":
        case "automations":
        case "settings":
            return param;
        default:
            return;
    }
};

export const getFirstTextValue = (bodyComponentGroup: LegoComponentGroup): string => {
    for (const { component } of bodyComponentGroup.rows) {
        if (component.type === "leaf") {
            if (component.leaf_component.type === "text") {
                return component.leaf_component.text_component.text;
            }

            continue;
        }

        if (component.type === "event") {
            const firstText = getFirstTextValue(component.event_component.component_group);
            if (!!firstText) {
                return firstText;
            }

            continue;
        }

        if (component.type === "horizontal-event") {
            const firstText = getFirstTextValue(component.horizontal_event_component.component_group);
            if (!!firstText) {
                return firstText;
            }

            continue;
        }

        if (component.type === "column") {
            if (component.column_component.first_column !== null) {
                const firstColumnText = getFirstTextValue(component.column_component.first_column);
                if (!!firstColumnText) {
                    return firstColumnText;
                }
            }

            if (component.column_component.second_column !== null) {
                const secondColumnText = getFirstTextValue(component.column_component.second_column);
                if (!!secondColumnText) {
                    return secondColumnText;
                }
            }

            if (component.column_component.third_column !== null) {
                const thirdColumnText = getFirstTextValue(component.column_component.third_column);
                if (!!thirdColumnText) {
                    return thirdColumnText;
                }
            }
        }
    }

    return "";
};

export const getFirstImageUrl = (bodyComponentGroup: LegoComponentGroup): string => {
    for (const { component } of bodyComponentGroup.rows) {
        switch (component.type) {
            case "leaf": {
                if (component.leaf_component.type === "image") {
                    return component.leaf_component.image_component.src;
                }

                continue;
            }
            case "form":
                const firstImage = getFirstImageUrl(component.form_component.component_group);
                if (!!firstImage) {
                    return firstImage;
                }
            case "question":
                continue;
            case "event": {
                const firstImage = getFirstImageUrl(component.event_component.component_group);
                if (!!firstImage) {
                    return firstImage;
                }

                continue;
            }
            case "horizontal-event":
                return component.horizontal_event_component.image_leaf_lego_component.src;
            case "column": {
                if (component.column_component.first_column !== null) {
                    const firstColumnImage = getFirstImageUrl(component.column_component.first_column);
                    if (!!firstColumnImage) {
                        return firstColumnImage;
                    }
                }

                if (component.column_component.second_column !== null) {
                    const secondColumnImage = getFirstImageUrl(component.column_component.second_column);
                    if (!!secondColumnImage) {
                        return secondColumnImage;
                    }
                }

                if (component.column_component.third_column !== null) {
                    const thirdColumnImage = getFirstImageUrl(component.column_component.third_column);
                    if (!!thirdColumnImage) {
                        return thirdColumnImage;
                    }
                }

                continue;
            }
            case "automation-triggered-event":
            case "automation-upcoming-events":
                continue;
        }
    }

    return "";
};

export const createNegativeLegoComponent = (id: number, body: PostLegoComponentBody): LegoComponent => {
    const key = v4();

    const { type } = body;
    switch (type) {
        case "leaf":
            return {
                id,
                key,
                type: "leaf",
                leaf_component: createNegativeLeafLegoComponent(id, body),
                padding_style: null,
            };
        case "form":
            return {
                id,
                key,
                type: "form",
                form_component: createNegativeFormLegoComponent(id, body),
                padding_style: null,
            };
        case "question":
            return {
                id,
                key,
                type: "question",
                question_component: createNegativeQuestionLegoComponent(id, body),
                padding_style: null,
            };
        case "event":
            return {
                id,
                key,
                type: "event",
                event_component: createNegativeEventLegoComponent(id, -1, body),
                padding_style: null,
            };
        case "column":
            return {
                id,
                key,
                type: "column",
                column_component: createNegativeColumnLegoComponent(id, body),
                padding_style: null,
            };
        case "horizontal-event":
            return {
                id,
                key,
                type: "horizontal-event",
                horizontal_event_component: createNegativeHorizontalEventLegoComponent(id, -1, body),
                padding_style: null,
            };
        case "automation-upcoming-events":
            return {
                id,
                key,
                type: "automation-upcoming-events",
                automation_upcoming_events_component: createNegativeAutomationUpcomingEventsLegoComponent(id, body),
                padding_style: null,
            };
        case "automation-triggered-event":
            return {
                id,
                key,
                type: "automation-triggered-event",
                automation_triggered_event_component: createNegativeAutomationTriggeredEventLegoComponent(id, body),
                padding_style: null,
            };
    }
};

const createNegativeLeafLegoComponent = (id: number, { leaf }: PostLegoComponentBodyWithLeaf): LeafLegoComponent => {
    const { type: leafType } = leaf;
    switch (leafType) {
        case "text":
            return {
                id,
                type: "text",
                text_component: {
                    id,
                    text: leaf.text.text ?? "",
                    text_type: leaf.text.text_type,
                    style: null,
                    text_configs: null,
                },
            };
        case "button":
            return {
                id,
                type: "button",
                button_component: {
                    id,
                    text: leaf.button.text ?? "",
                    href: leaf.button.href,
                    style: null,
                },
            };
        case "promo-code":
            return {
                id,
                type: "promo-code",
                promo_code_component: {
                    id,
                    text: leaf.promo_code.text ?? "",
                    style: null,
                },
            };
        case "image":
            return {
                id,
                type: "image",
                image_component: {
                    id,
                    src: leaf.image.src,
                    href: leaf.image.href,
                    max_height_px: leaf.image.max_height_px ?? null,
                    style: null,
                },
            };
        case "divider":
            return {
                id,
                type: "divider",
                divider_component: {
                    id,
                    style: null,
                },
            };
        case "spacer":
            return {
                id,
                type: "spacer",
                spacer_component: {
                    id,
                    spacing_px: leaf.spacer.spacing_px,
                },
            };
        case "input":
            return {
                id,
                type: "input",
                input_component: {
                    id,
                    type: leaf.input.type,
                    required: leaf.input.required,
                },
            };
        case "option":
            return {
                id,
                type: "option",
                option_component: {
                    id,
                    text: leaf.option.text,
                    tags: leaf.option.tags,
                    genres: leaf.option.genres,
                },
            };
    }
};

export const createNegativeFormLegoComponent = (
    id: number,
    { form }: PostLegoComponentBodyWithForm,
): FormLegoComponent => {
    return {
        id,
        name: form.name,
        button_text: form.button_text,
        redirect_url: form.redirect_url,
        tags: form.tags ?? [],
        genres: form.genres ?? [],
        email_recipients: form.recipient_emails,
        component_group: {
            id: -1,
            rows: form.group.map((postComponent, i) => ({
                id: -1 * (i + 1),
                ordinal: i + 1,
                component: createNegativeLegoComponent(-1, postComponent),
            })),
        },
        success_component_group: !form.success_group
            ? null
            : {
                  id: -1,
                  rows: form.success_group.map((postComponent, i) => ({
                      id: -1 * (i + 1),
                      ordinal: i + 1,
                      component: createNegativeLegoComponent(-1, postComponent),
                  })),
              },
    };
};

export const createNegativeQuestionLegoComponent = (
    id: number,
    { question }: PostLegoComponentBodyWithQuestion,
): QuestionLegoComponent => {
    return {
        id,
        type: question.type,
        required: question.required,
        component_group: {
            id: -1,
            rows: question.group.map((postComponent, i) => ({
                id: -1 * (i + 1),
                ordinal: i + 1,
                component: createNegativeLegoComponent(-1, postComponent),
            })),
        },
    };
};

export const createNegativeEventLegoComponent = (
    id: number,
    groupId: number,
    { event }: PostLegoComponentBodyWithEvent,
): EventLegoComponent => {
    return {
        id,
        event: event.event,
        component_group: {
            id: groupId,
            rows: event.group.map((postComponent, i) => ({
                id: -1 * (i + 1),
                ordinal: i + 1,
                component: {
                    id: -1 * (i + 1),
                    type: "leaf",
                    leaf_component: createNegativeLeafLegoComponent(-1, postComponent),
                    padding_style: null,
                },
            })),
        },
    };
};

export const createNegativeHorizontalEventLegoComponent = (
    id: number,
    groupId: number,
    { horizontal_event }: PostLegoComponentBodyWithHorizontalEvent,
): HorizontalEventLegoComponent => ({
    id,
    event: horizontal_event.event,
    component_group: {
        id: groupId,
        rows: horizontal_event.group.map((postComponent, i) => ({
            id: -1 * (i + 1),
            ordinal: i + 1,
            component: {
                id: -1 * (i + 1),
                type: "leaf",
                leaf_component: createNegativeLeafLegoComponent(-1, postComponent),
                padding_style: null,
            },
        })),
    },
    image_leaf_lego_component: { ...horizontal_event.image, id: 0, style: null, max_height_px: null },
    image_alignment: horizontal_event.image_alignment,
});

const createNegativeAutomationUpcomingEventsLegoComponent = (
    id: number,
    { automation_upcoming_events }: PostLegoComponentBodyWithAutomationUpcomingEvents,
): AutomationUpcomingEventsLegoComponent => ({
    id,
    event_arrangement: automation_upcoming_events.event_arrangement,
    event_lookahead_amount: automation_upcoming_events.event_lookahead_amount,
    event_lookahead_unit: automation_upcoming_events.event_lookahead_unit,
    event_order_by: automation_upcoming_events.event_order_by,
    event_order_by_direction: automation_upcoming_events.event_order_by_direction,
    max_events: automation_upcoming_events.max_events,
    tags: automation_upcoming_events.tags.map((tag, i) => ({ ...tag, id: -1 * (i + 1) })),
    genres: automation_upcoming_events.genres.map((genre, i) => ({ ...genre, id: -1 * (i + 1) })),
});

const createNegativeAutomationTriggeredEventLegoComponent = (
    id: number,
    { automation_triggered_event }: PostLegoComponentBodyWithAutomationTriggeredEvent,
): AutomationTriggeredEventLegoComponent => ({
    id,
    event_arrangement: automation_triggered_event.event_arrangement,
});

const createNegativeColumnLegoComponent = (
    id: number,
    { column }: PostLegoComponentBodyWithColumn,
): ColumnLegoComponent => {
    return {
        id,
        first_column: {
            id: -1,
            rows: [
                {
                    id: -1,
                    component: createNegativeLegoComponent(-1, column.first_column[0]),
                    ordinal: 1,
                },
            ],
        },
        second_column: {
            id: -1,
            rows: [
                {
                    id: -1,
                    component: createNegativeLegoComponent(-1, column.second_column[0]),
                    ordinal: 1,
                },
            ],
        },
        ...(column.column_type === "three"
            ? {
                  column_type: "three",
                  third_column: {
                      id: -1,
                      rows: [
                          {
                              id: -1,
                              component: createNegativeLegoComponent(-1, column.third_column[0]),
                              ordinal: 1,
                          },
                      ],
                  },
              }
            : {
                  column_type: "two",
                  third_column: null,
              }),
        first_column_vertical_alignment: "middle",
        second_column_vertical_alignment: "middle",
        third_column_vertical_alignment: "middle",
        column_format: column.column_format,
    };
};

export const createNegativeLegoComponentId = (rows: LegoComponentGroupRow[]) => {
    let largestNegative = 0;
    for (const { component } of rows) {
        if (component.id < largestNegative) {
            largestNegative = component.id;
        }
    }

    return largestNegative;
};

export const emptyLegoImageUrl = getImageUrl("/assets/manager/event-image-filler.webp");
export const emptyLegoImageSmallUrl = getImageUrl("/assets/manager/lego-image-filler-small.webp");

export const getAutomationUpcomingEventsComponentPreviewComponents = ({
    eventArrangement,
    maxEvents,
    mergedTemplateStyles,
}: {
    eventArrangement: LegoEventArrangement;
    maxEvents: number;
    mergedTemplateStyles: MergedLegoTemplateStyles;
}): LegoComponent[] => {
    let fakeComponentCount = 1;
    const actualMaxEvents = Math.min(Math.max(maxEvents, 1), 8);

    const components: LegoComponent[] = [];
    for (let eventNumber = 1; eventNumber <= actualMaxEvents; eventNumber++) {
        const fakeEventComponent = getFakeEventComponent({
            eventArrangement:
                eventArrangement === "horizontal-zigzag"
                    ? eventNumber % 2 === 1
                        ? "horizontal-left"
                        : "horizontal-right"
                    : eventArrangement,
            eventStyle:
                eventArrangement === "vertical"
                    ? mergedTemplateStyles.event_style
                    : mergedTemplateStyles.horizontal_event_style,
            fakeComponentCount,
            eventNumber: actualMaxEvents === 1 ? undefined : eventNumber,
        });

        components.push(...fakeEventComponent);

        fakeComponentCount += Math.abs(fakeEventComponent[0].id) + 1;
    }

    return components;
};

export const getAutomationTriggeredEventComponentPreviewComponents = ({
    eventArrangement,
    mergedTemplateStyles,
}: {
    eventArrangement: SingleLegoEventArrangement;
    mergedTemplateStyles: MergedLegoTemplateStyles;
}): LegoComponent[] =>
    getFakeEventComponent({
        eventArrangement,
        eventStyle:
            eventArrangement === "vertical"
                ? mergedTemplateStyles.event_style
                : mergedTemplateStyles.horizontal_event_style,
        fakeComponentCount: 1,
        isTriggered: true,
    });

const getFakeEventComponent = ({
    eventArrangement,
    eventStyle,
    eventNumber,
    fakeComponentCount,
    isTriggered = false,
}: {
    eventArrangement: SingleLegoEventArrangement;
    eventStyle: NonNullableLegoEventStyle;
    fakeComponentCount: number;
    eventNumber?: number;
    isTriggered?: boolean;
}): LegoComponent[] => {
    const componentId = fakeComponentCount * -1;

    const imageLeafComponent: ImageLeafLegoComponent = {
        id: componentId,
        href: "",
        src: emptyLegoImageUrl,
        max_height_px: null,
        style: null,
    };

    const imageComponent: LegoComponentWithLeaf = {
        id: componentId - 1,
        type: "leaf",
        leaf_component: {
            id: componentId - 2,
            type: "image",
            image_component: imageLeafComponent,
        },
        padding_style: null,
    };

    const eventText = isTriggered
        ? "Triggered event"
        : `Upcoming event${eventNumber !== undefined ? ` ${eventNumber}` : ""}`;

    const sortedComponents = sortByEventOrdinals<LegoComponentWithLeaf>(
        {
            image: eventArrangement === "vertical" ? imageComponent : undefined,
            name: {
                id: componentId - 3,
                type: "leaf",
                leaf_component: {
                    id: componentId - 4,
                    type: "text",
                    text_component: {
                        id: componentId - 5,
                        text: `${eventText} name`,
                        text_type: eventStyle.name_text_type,
                        text_configs: null,
                        style: null,
                    },
                },
                padding_style: null,
            },
            datetime: {
                id: componentId - 6,
                type: "leaf",
                leaf_component: {
                    id: componentId - 7,
                    type: "text",
                    text_component: {
                        id: componentId - 8,
                        text: `${eventText} time`,
                        text_type: eventStyle.datetime_text_type,
                        text_configs: null,
                        style: null,
                    },
                },
                padding_style: null,
            },
            door_open_time: {
                id: componentId - 9,
                type: "leaf",
                leaf_component: {
                    id: componentId - 10,
                    type: "text",
                    text_component: {
                        id: componentId - 11,
                        text: `${eventText} door time`,
                        text_type: eventStyle.door_open_time_text_type,
                        text_configs: null,
                        style: null,
                    },
                },
                padding_style: null,
            },
            button: {
                id: componentId - 12,
                type: "leaf",
                leaf_component: {
                    id: componentId - 13,
                    type: "button",
                    button_component: {
                        id: componentId - 14,
                        text: eventStyle.button_text,
                        href: "",
                        style: null,
                    },
                },
                padding_style: null,
            },
            location: {
                id: componentId - 15,
                type: "leaf",
                leaf_component: {
                    id: componentId - 16,
                    type: "text",
                    text_component: {
                        id: componentId - 17,
                        text: `${eventText} location`,
                        text_type: eventStyle.location_text_type,
                        text_configs: null,
                        style: null,
                    },
                },
                padding_style: null,
            },
            description: {
                id: componentId - 18,
                type: "leaf",
                leaf_component: {
                    id: componentId - 19,
                    type: "text",
                    text_component: {
                        id: componentId - 20,
                        text: `This is the ${eventText.toLocaleLowerCase()} description.`,
                        text_type: eventStyle.description_text_type,
                        text_configs: null,
                        style: null,
                    },
                },
                padding_style: null,
            },
        },
        eventStyle,
    );

    if (eventArrangement === "vertical") {
        return sortedComponents;
    }

    return [
        {
            id: componentId - 21,
            type: "horizontal-event",
            horizontal_event_component: {
                id: componentId - 22,
                event: null,
                image_leaf_lego_component: imageLeafComponent,
                image_alignment: eventArrangement === "horizontal-left" ? "left" : "right",
                component_group: convertComponentsToPreviewGroup(sortedComponents),
            },
            padding_style: null,
        },
    ];
};

export const swapVerticalAlignmentOnColumnReorder = (
    newIndex: number,
    oldIndex: number,
    columnLegoComponent: ColumnLegoComponent,
) => {
    if (oldIndex === 0 && newIndex === 1) {
        const tempAlignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 0 && newIndex === 2) {
        const tempAlignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 1 && newIndex === 2) {
        const tempAlignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 1 && newIndex === 0) {
        const tempAlignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 2 && newIndex === 1) {
        const tempAlignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 2 && newIndex === 0) {
        const tempAlignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = tempAlignment;
    }
};

export const createDummyGroupWithComponent = (component: LegoComponent): LegoComponentGroup => ({
    id: 0,
    rows: [
        {
            id: 0,
            ordinal: 1,
            component,
        },
    ],
});

export const formatEventStyleForPatchRequest = (eventStyle: NonNullableLegoEventStyle) => ({
    ...eventStyle,
    description_character_limit:
        eventStyle.description_character_limit === undefined || eventStyle.description_character_limit <= 0
            ? undefined
            : eventStyle.description_character_limit,
    unset_description_character_limit:
        eventStyle.description_character_limit === undefined || eventStyle.description_character_limit <= 0,
});

export const removeHeaderAndFooterStyleComponentGroups = (obj: MergedLegoTemplateStyles) => {
    const copyObj = { ...obj };
    copyObj.header_style = null;
    copyObj.footer_style = { ...copyObj.footer_style, component_group: null };

    return copyObj;
};

export const convertComponentsToPreviewGroup = (components: LegoComponent[]): LegoComponentGroup => ({
    id: 0,
    rows: components.map((component, i) => ({
        id: component.id,
        component,
        ordinal: i + 1,
    })),
});

export const getDisplayTextForFormTextSubmissions = ({
    inputSubmissions,
    questionSubmissions,
}: {
    inputSubmissions: LegoFormInputSubmission[];
    questionSubmissions: LegoFormQuestionSubmission[];
}) =>
    [
        ...inputSubmissions
            .filter(({ input_component }) => input_component.type === "short-answer")
            .map(({ value }) => value),
        ...questionSubmissions.map(({ selected_options }) => selected_options.map(({ text }) => text).join(", ")),
    ].join(", ");

export const scrollIntoViewIfNeeded = (target: HTMLElement) => {
    if (target.getBoundingClientRect().bottom > window.innerHeight) {
        target.scrollIntoView(false);
    }

    if (target.getBoundingClientRect().top < 0) {
        target.scrollIntoView();
    }
};

export const getLegoValidationData = ({
    legoTemplate,
    mergedTemplateStyles,
    isPage = false,
}: {
    legoTemplate: LegoTemplate;
    mergedTemplateStyles: MergedLegoTemplateStyles;
    isPage?: boolean;
}) => {
    let isValid = true;
    let firstInvalidComponentId: null | number = null;
    let newTab: LegoTab = "main";

    const firstInvalidComponentIdTemplate = getInvalidComponentIdLegoTemplate(legoTemplate);
    const firstInvalidHeaderComponentId = getInvalidComponentIdLegoTemplateHeader(mergedTemplateStyles);
    const firstInvalidFooterComponentId = !isPage
        ? getInvalidComponentIdLegoTemplateFooter(mergedTemplateStyles)
        : null;

    if (!!firstInvalidComponentIdTemplate) {
        newTab = "main";
        isValid = false;
        firstInvalidComponentId = firstInvalidComponentIdTemplate;
    } else if (!!firstInvalidHeaderComponentId) {
        newTab = "style";
        isValid = false;
        firstInvalidComponentId = firstInvalidHeaderComponentId;
    } else if (!!firstInvalidFooterComponentId) {
        newTab = "style";
        isValid = false;
        firstInvalidComponentId = firstInvalidFooterComponentId;
    }

    return {
        newTab,
        isValid,
        firstInvalidComponentId,
    };
};
