import React from "react";

import { delay } from "../util/delay";

export const Div100vh = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    ({ style, ...other }, ref) => {
        const height = use100vh();

        const styleWithRealHeight = {
            ...style,
            height: height ? `${height}px` : "100vh",
        };

        return <div ref={ref} style={styleWithRealHeight} {...other} />;
    },
);

Div100vh.displayName = "Div100vh";

const use100vh = (): number | null => {
    const [height, setHeight] = React.useState<number | null>(measureHeight);

    const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

    const setMeasuredHeight = React.useCallback(() => {
        const measuredHeight = measureHeight();
        setHeight(measuredHeight);
    }, []);

    React.useEffect(() => {
        if (!wasRenderedOnClientAtLeastOnce) return;

        function setMeasuredHeight() {
            const measuredHeight = measureHeight();
            setHeight(measuredHeight);
        }

        window.addEventListener("resize", setMeasuredHeight);
        return () => window.removeEventListener("resize", setMeasuredHeight);
    }, [wasRenderedOnClientAtLeastOnce, setMeasuredHeight]);

    React.useEffect(() => {
        delay(50).then(() => setMeasuredHeight());
    }, [setMeasuredHeight]);

    return wasRenderedOnClientAtLeastOnce ? height : null;
};

function measureHeight(): number | null {
    if (!isClient()) return null;
    return window.innerHeight;
}

// Once we ended up on the client, the first render must look the same as on
// the server so hydration happens without problems. _Then_ we immediately
// schedule a subsequent update and return the height measured on the client.
// It's not needed for CSR-only apps, but is critical for SSR.
const useWasRenderedOnClientAtLeastOnce = () => {
    const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] = React.useState(false);

    React.useEffect(() => {
        if (isClient()) {
            setWasRenderedOnClientAtLeastOnce(true);
        }
    }, []);
    return wasRenderedOnClientAtLeastOnce;
};

const isClient = () => typeof window !== "undefined" && typeof document !== "undefined";
