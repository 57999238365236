import React from "react";

import { CustomDomain } from "../models/customDomain";
import { EmailSenderAddress } from "../models/emailSenderAddress";
import { GitbookTokenInfo } from "../models/gitbookTokenInfo";
import { IPLocation } from "../models/ipLocation";
import { Organization } from "../models/organization";
import { User } from "../models/user";

interface AppState {
    user: User | undefined;
    organization: Organization | undefined;
    organizations: Organization[];
    isMobile: boolean;
    mounted: boolean;
    ipLocation: IPLocation | undefined;
    gitbookTokenInfo: GitbookTokenInfo | undefined;
    activeFeatureFlags: ActiveFeatureFlags;
    customColors: string[];
    primaryCustomDomain: CustomDomain | null;
    primaryEmailSenderAddress: EmailSenderAddress | null;
    setCustomDomains: React.Dispatch<React.SetStateAction<CustomDomain[] | null>>;
    setEmailSenderAddresses: React.Dispatch<React.SetStateAction<EmailSenderAddress[] | null>>;
    saveCustomColor: (color: string) => void;
    resetUser: (user?: User) => void;
    resetOrganization: React.Dispatch<React.SetStateAction<Organization | undefined>>;
    resetOrganizations: (oranizations: Organization[]) => void;
    resetGitbookTokenInfo: React.Dispatch<React.SetStateAction<GitbookTokenInfo | undefined>>;
}

export interface ActiveFeatureFlags {
    allowEmailCampaigns: boolean;
    allowSmsCampaigns: boolean;
    allowSocialMedia: boolean;
    allowRichTextEditing: boolean;
    allowEntri: boolean;
}

export const AppContext = React.createContext({} as AppState);
