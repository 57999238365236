export const parseInteger = (numberStr: string | undefined) => {
    if (numberStr) {
        const num = parseInt(numberStr);
        if (!isNaN(num)) {
            return num;
        }
    }
};

export const parseNumber = (numberStr: string | undefined) => {
    if (numberStr) {
        const num = parseFloat(numberStr);
        if (!isNaN(num)) {
            return num;
        }
    }
};
