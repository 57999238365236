import Router from "next/router";
import NProgress from "nprogress";

NProgress.configure({ showSpinner: false, trickleSpeed: 200 });

const handleRouteStart = (_route: string, { shallow }: { shallow: boolean }) => {
    if (!shallow) {
        NProgress.start();
    }
};

const handleRouteEnd = (_route: string, { shallow }: { shallow: boolean }) => {
    if (!shallow) {
        NProgress.done();
    }
};

Router.events.on("routeChangeStart", handleRouteStart);
Router.events.on("routeChangeComplete", handleRouteEnd);
Router.events.on("routeChangeError", handleRouteEnd);

const TopProgressBar = () => null;

export default TopProgressBar;
