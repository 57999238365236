import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

import { IconProps } from "../util/icon";

export const LeftChevronIcon: React.FC<IconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 16 16">
        <path d="M9.78 12.78a.75.75 0 0 1-1.06 0L4.47 8.53a.75.75 0 0 1 0-1.06l4.25-4.25a.751.751 0 0 1 1.042.018.751.751 0 0 1 .018 1.042L6.06 8l3.72 3.72a.75.75 0 0 1 0 1.06Z" />
    </SvgIcon>
);
